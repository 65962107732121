<template>
    <a
        :href="`tel:${phoneNumber}`"
        data-test="phoneContact"
        :class="[
            'phoneContactCard',
            {
                'phoneContactCard--compact': compact,
                'phoneContactCard--rounded': rounded,
            },
        ]"
        @click="
            () =>
                trackEventForUserActions(
                    componentIdentifier,
                    'Phone Number',
                    phoneNumber,
                )
        "
    >
        <SVGIcon :icon-name="icon" class="icon__phone" />
        <span class="phoneContactCard__contactInfo">
            <p
                class="phoneContactCard__phoneNumber"
                data-test="phoneContact_number"
            >
                {{ phoneNumber }}
            </p>
            <span
                v-if="consultingHours"
                data-test="phoneContact_hours"
                class="phoneContactCard__hours"
            >
                <ul>
                    <li
                        v-for="(hour, index) in splitHours(consultingHours)"
                        :key="index"
                    >
                        {{ hour }}
                    </li>
                </ul>
            </span>
        </span>
    </a>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'PhoneContactCard',

    components: {
        SVGIcon,
    },

    mixins: [gtmTracking],

    props: {
        phoneNumber: {
            type: String,
            default: '(030) 220 128 831',
        },

        consultingHours: {
            type: String,
            required: false,
        },

        componentIdentifier: {
            type: String,
            required: true,
        },

        compact: {
            type: Boolean,
        },

        rounded: {
            type: Boolean,
        },

        icon: {
            type: String,
            default: 'phone-actions-smile',
        },
    },

    methods: {
        splitHours(hours) {
            return hours.split('|')
        },
    },
}
</script>

<style lang="stylus">
.phoneContactCard {
    background: $cs-slate-050;
    color: $cs-slate-400;
    display: flex;
    letter-spacing: 0.2px;
    padding: $cs-space $cs-spaces.onehalf;
    text-decoration: none;

    &:hover .icon__phone {
        animation: shake 1.8s infinite cubic-bezier(.36,.07,.19,.97) both;
        transform: rotate(0deg);
    }

    &__phoneNumber {
        font-size: 20px;
        font-weight: 800;
        margin: 0;
        vertical-align: middle;
        white-space: nowrap;

        .phoneContactCard--compact & {
            font-size: 16px;
        }
    }

    &__hours {
        margin-top: $cs-spaces.half;
        white-space: nowrap;
    }

    .icon__phone {
        color: $cs-primary;
        flex: 0 0 auto;
        height: 40px;
        margin-right: $cs-spaces.onehalf;
        width: 40px;

        .phoneContactCard--compact & {
            height: 34px;
            margin-right: $cs-space;
            width: 34px;
        }
    }

    &--compact {
        align-items: center;
        font-size: 12px;
        justify-content: center;
        padding: $cs-spaces.half $cs-space;
    }

    &--rounded {
        border-radius: $cs-border-radius;
    }
}

@keyframes shake {
    0%, 40% {
        transform: rotate(-2deg);
    }

    5%, 35% {
        transform: rotate(4deg);
    }

    10%, 20%, 30% {
        transform: rotate(-6deg);
    }

    15%, 25% {
        transform: rotate(6deg);
    }
}
</style>
