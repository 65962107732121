<template lang="html">
    <div class="bestMatch">
        <SVGIcon v-if="icon" :icon-name="icon" class="icon__bestMatch" />
        <p class="bestMatch__text">
            {{ textStart }}
            <span
                class="bestMatch__link csLink"
                @click.stop="displayDialog = true"
                @click.once="
                    () =>
                        trackEventForUserActions(
                            'Best Match Dialog Window',
                            'Open Dialog Window',
                            '',
                        )
                "
            >
                {{ ctaText }}
            </span>
            <template v-if="textEnd">{{ textEnd }}</template>
        </p>

        <v-dialog
            v-model="displayDialog"
            max-width="460"
            content-class="dialogWindow"
            :fullscreen="this.$vuetify.breakpoint.xsOnly"
        >
            <DialogWindow
                v-bind="bestMatchDialog"
                component-identifier="Best Match Dialog Window"
                @closeDialog="displayDialog = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import DialogWindow from '@/components/molecules/dialog/dialogWindow.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'BestMatchDialog',

    components: {
        SVGIcon,
        DialogWindow,
    },

    mixins: [gtmTracking],

    props: {
        icon: {
            type: String,
            default: 'chemie-stimmt',
        },
        ctaText: {
            type: String,
            default: '„Chemie stimmt“',
        },
        textStart: {
            type: String,
            default: 'Eine Auswahl an Alltagshelfern – immer mit',
        },
        textEnd: {
            type: String,
            default: 'Garantie',
        },
    },

    data: () => ({
        displayDialog: false,
    }),

    computed: {
        bestMatchDialog() {
            return {
                icon: 'chemie-stimmt',
                title: this.$t('WITH_CHEMISTRY_GUARANTEE'),
                content: this.$t('THE_CHEMISTRY_GUARANTEE_EXPLANATION'),
            }
        },
    },
}
</script>

<style lang="stylus">
.bestMatch {
    display: flex;
    margin-top: $cs-spaces.double;

    &__text {
        margin: 0;
    }

    &__link {
        color: $cs-primary;
        cursor: pointer;
    }

    .icon__bestMatch {
        flex: 0 0 auto;
        height: 42px;
        margin-right: $cs-space;
        width: 42px;
    }
}
</style>
