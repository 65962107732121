<template>
    <div class="mediaTrust">
        <p class="mediaTrust__label">{{ label }}</p>
        <a
            v-for="(list, index) in mediaList"
            :key="index"
            href="/newsroom"
            :title="list.name"
            class="mediaTrust__link"
            :data-test="`mediaTrust_${index}_link`"
            @click="
                () =>
                    trackEventForUserActions(
                        'Media Trust',
                        list.name,
                        '/newsroom',
                    )
            "
        >
            <DynamicImage
                :image-name="list.image"
                class="mediaTrust__image lazyload"
                :alt="list.name"
            />
        </a>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import DynamicImage from '@/components/atoms/image/dynamicImage.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'MediaIconList',

    components: {
        SVGIcon,
        DynamicImage,
    },

    mixins: [gtmTracking],

    props: {
        label: {
            type: String,
            default: 'Bekannt aus:',
        },
        mediaList: {
            type: Array,
            default: () => [
                {
                    name: 'Die Zeit',
                    image: 'media/logos_die-zeit_color.png',
                },
                {
                    name: 'Bild',
                    image: 'media/logos_bild_color.png',
                },
                {
                    name: 'Manager Magazin',
                    image: 'media/logos_manager-magazin_color.png',
                },
                {
                    name: 'NTV',
                    image: 'media/logos_ntv_color.png',
                },
                {
                    name: 'WIWO',
                    image: 'media/logos_wiwo_color.png',
                },
                {
                    name: 'WDR',
                    image: 'media/logos_WDR_color.png',
                },
                {
                    name: 'MDR',
                    image: 'media/logos_mdr_color.png',
                },
            ],
        },
    },
}
</script>

<style lang="stylus">
.mediaTrust {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    justify-content: center;

    @media $display-breakpoints.md-and-up {
        justify-content: space-between;
    }

    &__label {
        margin: 0;
        text-align: center;
        width: 100%;

        @media $display-breakpoints.md-and-up {
            margin: 0 $cs-space 0 0;
            text-align: left;
            width: auto;
        }
    }

    &__link {
        display: block;
        flex: 0 0;
        margin: $cs-spaces.half;

        @media $display-breakpoints.md-and-up {
            margin: 0 $cs-spaces.half;
        }

        &:hover .mediaTrust__image {
            filter: grayscale(0);
            opacity: 1;
        }
    }

    &__image {
        display: block;
        filter: grayscale(1);
        height: 18px;
        opacity: 0.3;
        transition: all ease-in-out 200ms;
        width: auto;

        @media $display-breakpoints.sm-and-up {
            height: 20px;
        }

        @media $display-breakpoints.md-and-up {
            height: 24px;
        }
    }
}
</style>
