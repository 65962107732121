<template>
    <div
        :class="[
            'uspList',
            { 'uspList--bar': bar, 'uspList--flow': doesListFlow },
        ]"
    >
        <component
            :is="usp.linkTo ? 'nuxt-link' : 'div'"
            v-for="(usp, index) in uspList"
            :key="index"
            :to="usp.linkTo"
            :class="['uspList__item', { 'text--large': !bar }]"
        >
            <SVGIcon :icon-name="usp.icon" class="icon__usp" />
            {{ usp.text }}
        </component>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

export default {
    name: 'UspList',

    components: {
        SVGIcon,
    },

    props: {
        uspList: {
            type: Array,
            required: true,
        },

        bar: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        doesListFlow() {
            return this.uspList.length > 4
        },
    },
}
</script>

<style lang="stylus">
.uspList {
    display: flex;
    flex-direction: column;

    @media $display-breakpoints.sm-and-up {
        flex-direction: row;
        justify-content: center;
        margin: 0 (-($cs-space));
    }

    &__item {
        align-items: center;
        display: flex;
        flex: 1 0;
        justify-content: flex-start;
        letter-spacing: 0.2px;
        margin: $cs-space 0 $cs-spaces.half;
        word-break: break-word;
        color: inherit;
        text-decoration: none;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @media $display-breakpoints.sm-and-up {
            flex-direction: column;
            justify-content: center;
            margin: 0 $cs-space;
            text-align: center;
        }
    }

    &--flow {
        flex-wrap: wrap;

        @media $display-breakpoints.sm-and-up {
            margin: (-($cs-space));
        }

        .uspList__item {
            @media $display-breakpoints.sm-and-up {
                flex: 1 0 200px;
                margin: $cs-spaces.onehalf;
                max-width: 200px;
            }

            @media $display-breakpoints.lg-and-up {
                margin: $cs-space;
            }
        }
    }

    .icon__usp {
        color: $cs-primary;
        flex: 0 0 auto;
        height: 36px;
        margin: 0 $cs-spaces.onehalf 0 0;
        width: 36px;

        @media $display-breakpoints.sm-and-up {
            margin: 0 0 $cs-space;
        }
    }

    &--bar {
        background: $cs-slate-050;
        border-radius: $cs-border-radius;
        margin: 0;

        @media $display-breakpoints.sm-and-up {
            padding: $cs-spaces.onehalf $cs-space;
        }

        .uspList__item {
            justify-content: flex-start;

            @media $display-breakpoints.sm-and-up {
                margin: 0;
                padding: 0 $cs-space;
            }

            @media $display-breakpoints.lg-and-up {
                flex-direction: row;
                justify-content: center;
                margin: 0;
                text-align: left;
            }
        }

        .icon__usp {
            @media $display-breakpoints.lg-and-up {
                margin: 0 $cs-space 0 0;
            }
        }

        .uspList__item:not(:last-child) {
            @media $display-breakpoints.sm-and-up {
                border-right: 1px solid $cs-slate-200;
            }
        }
    }
}
</style>
