<template>
    <div
        class="info45b"
        :class="{ 'info45b--flat': flat }"
        data-test="info-45b"
        @click="handleClick()"
    >
        <SVGIcon icon-name="accounting-coins-bill" class="info45b__icon" />

        <div class="info45b__copy">
            <div class="info45b__title">
                Finanzierungsmöglichkeiten & Preise
                <SVGIcon
                    class="info45b__chevron-right"
                    icon-name="chevron-right"
                />
            </div>
            <div>
                Hinweise zur Abrechnung unserer Leistungen
            </div>
        </div>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

export default {
    name: 'Info45b',

    components: {
        SVGIcon,
    },

    props: {
        flat: Boolean,
    },

    methods: {
        handleClick() {
            if (process.client) {
                window.location.href =
                    '/preise#price'
            }
        },
    },
}
</script>

<style lang="stylus">
.info45b {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 16px;
    border: 1px solid $cs-slate-200;
    border-radius: $cs-border-radius;
    margin-top: 20px;
    min-width: 65%;

    @media only screen and (max-width: 768px) {
        min-width: 85%;
    }

    @media only screen and (max-width: 500px) {
        min-width: 100%;
    }

    &--flat {
        border: none;
        padding: 0;
    }

    &__copy {
        flex-direction: column;
        font-size: 16px;
        margin-left: $cs-space;

        @media $display-breakpoints.sm-and-up {
            font-size: 20px;
        }
    }

    &__icon {
        width: $cs-spaces.triple;
        height: $cs-spaces.triple;
    }

    &__title {
        color: $cs-slate-400;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
    }

    &__chevron-right {
         margin-top: 2px;
         height: $cs-spaces.onehalf;
         width: $cs-spaces.onehalf;
     }
}
</style>
