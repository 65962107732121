<template>
    <div class="relatedArticle">
        <ResponsiveImage
            :image-name="article.image"
            class="relatedArticle__image"
            small-width
        />

        <div v-if="Boolean(splash)" class="floatSplash">
            <Splash
                :href="splash.href"
                :message="splash.message"
                :large="splash.large"
            />
        </div>

        <div class="relatedArticle__body">
            <p class="h4">{{ article.title }}</p>
            <p class="relatedArticle__text">{{ article.text }}</p>

            <CSLink
                v-if="article.ctaText"
                :data-test="`relatedArticle_link`"
                :href="article.ctaLink"
                :external="true"
                class="relatedArticle__link"
                @click="
                    () =>
                        trackEventForUserActions(
                            'Prefooter - Related Article',
                            article.ctaText,
                            article.ctaLink,
                        )
                "
            >
                {{ article.ctaText }}
            </CSLink>
        </div>
    </div>
</template>

<script>
import CSLink from '@/components/atoms/link/CSLink.vue'
import ResponsiveImage from '@/components/atoms/image/ResponsiveImage.vue'
import Splash from '@/components/atoms/splash'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'RelatedArticle',

    components: {
        ResponsiveImage,
        CSLink,
        Splash,
    },

    mixins: [gtmTracking],

    props: {
        article: {
            type: Object,
            required: true,
        },
        splash: {
            type: Object,
            default: null,
        },
    },
}
</script>

<style lang="stylus">
.relatedArticle {
    position: relative;
    margin: 0 0 $cs-spaces.triple;

    @media $display-breakpoints.sm-and-up {
        margin: 0;
    }

    &__image {
        border-radius: $cs-border-radius;
        height: 200px;
        object-fit: cover;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media $display-breakpoints.lg-and-up {
            height: 250px;
        }
    }

    .h4 {
        margin: $cs-spaces.onehalf 0 $cs-spaces.half;
    }

    &__text {
        margin: 0 0 $cs-space;
    }

    .floatSplash {
        position: absolute;
        top: 30%;
        right: -25px;
    }
}
</style>
