<template>
    <div :class="['timeline', { 'timeline--hasImages': hasImages }]">
        <div
            v-for="(item, i) in timeline"
            :key="i"
            :class="[
                'timeline__item',
                {
                    'timeline__item--dashed': item.dashed,
                    'timeline__item--highlight': item.highlight,
                    'timeline__item--wide': item.wide,
                    'timeline__item--banner': item.banner,
                },
            ]"
        >
            <DynamicImage
                v-if="item.image"
                :image-name="item.image"
                :class="[
                    'timeline__image',
                    { 'timeline__image--wide': item.wide },
                ]"
            />

            <div v-if="item.banner" :class="['banner_container']">
                <component
                    :is="item.contentComponent"
                    v-if="item.contentComponent && item.banner"
                    v-bind="item.contentProps"
                />
            </div>

            <h2 v-if="item.title" class="header__title">
                {{ item.title }}
            </h2>

            <div v-if="!item.wide && !item.banner" class="timeline__body">
                <div
                    :class="[
                        'timeline__dot',
                        { 'timeline__dot--icon': item.highlight },
                    ]"
                >
                    <SVGIcon
                        v-if="item.highlight"
                        :icon-name="item.icon"
                        class="icon__highlight"
                    />
                </div>

                <component
                    :is="item.contentComponent"
                    v-if="item.contentComponent && !item.banner"
                    v-bind="item.contentProps"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import DynamicImage from '@/components/atoms/image/dynamicImage.vue'
import CSButton from '@/components/atoms/button/CSButton.vue'

export default {
    name: 'Timeline',

    components: {
        SVGIcon,
        DynamicImage,
        CSButton,
    },

    props: {
        timeline: {
            type: Array,
        },
        fullWidth: {
            type: Boolean,
        },
    },

    computed: {
        hasImages() {
            return this.timeline.reduce(
                (acc, item) => acc || Boolean(item.image),
                false,
            )
        },
    },
}
</script>

<style lang="stylus">
.banner_container {
    &:before {
        content: none;
    }
}

.timeline {
    overflow: auto;

    .icon__highlight {
        color: $cs-primary;
        height: 32px;
        width: 32px;
    }

    h2 {
        @media $display-breakpoints.sm-and-up {
            width: 50%;
        }
    }

    &__item {
        margin: (-($cs-spaces.quarter)) 0 0;
        position: relative;
        padding: 0 0 $cs-spaces.triple;

        &:before {
            border-left: 3px $cs-slate-200 solid;
            content: '';
            height: 100%;
            left: 20px;
            position: absolute;
            width: 1px;
            z-index: 0;
        }

        &:first-child:before {
            top: $cs-spaces.quarter;
        }

        &:last-child {
            padding-bottom: 0

            &:before {
                content: none;
            }
        }

        &--highlight {
            padding:0 0 $cs-spaces.triple;
        }

        &--wide {
            padding: 0;

            + .timeline__item:before {
                top: $cs-spaces.quarter;
            }

            &:before {
                content: none;
            }
        }

        /*
        &--banner {
            padding: 0;

            + .timeline__item:before {
                top: $cs-spaces.quarter;
                content: none;
            }

            &:before {
                content: none;
            }
        } */

        &--dashed:before {
            border-left-style: dashed;
        }
    }

    &__body {
        display: flex;

        .timeline--hasImages & {
            @media $display-breakpoints.sm-and-up {
                max-width: 50%;
            }
        }
    }

    &__image {
        display: block;
        width: "calc(100% - %s)" % $cs-spaces.triple;
        margin: 0 0 $cs-spaces.double $cs-spaces.triple;

        &--wide {
            margin: 0 0 $cs-spaces.double;
            width: 100%;
        }

        @media $display-breakpoints.sm-and-up {
            float: right;
            clear: both;
            width: 40%;
            margin: 0 0 $cs-space;
            max-width: 400px;
        }
    }

    &__dot {
        background: $cs-white;
        border-radius: $cs-border-radius-round;
        border: 3px $cs-slate-200 solid;
        flex: 0 0 auto;
        height: 16px;
        margin: $cs-spaces.quarter 13px 0;
        width: 16px;
        z-index: 1;

        &--icon {
            align-items: center;
            border: 0;
            display: flex;
            height: auto;
            justify-content: center;
            margin: 0;
            padding: $cs-space 0;
            width: 42px;
        }
    }
}
</style>
