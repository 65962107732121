<template>
    <div
        class="healthInsuranceInfo"
        :class="{ 'healthInsuranceInfo--flat': flat }"
        data-test="healthInsuranceInfo-info"
        @click="handleClick()"
    >
        <div class="heathInsurance__icon">
            <img
                class="iconsSymbol"
                src="https://s3.eu-central-1.amazonaws.com/static.careship.de/corporate-identity/careship-ambulant/logos/CS-Ambulant-Logo-Element.png"
            />
            <div class="iconBackground"></div>
        </div>

        <div class="heathInsurance__copy">
            <div class="heathInsurance__title">
                <h6>Neu: Careship Ambulant</h6>
                <span>Dauerhafte Abrechnung über die Pflegekasse</span>
                <a href="/senioren-ratgeber/alltagshilfe-abrechnen/">
                    Jetzt mehr erfahren
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HealthInsuranceInfo',

    props: {
        flat: Boolean,
    },

    methods: {
        handleClick() {
            if (process.client) {
                window.location.href =
                    '/senioren-ratgeber/alltagshilfe-abrechnen/'
            }
        },
    },
}
</script>

<style lang="stylus">
.iconCircle {
    widht: 50%;
    height: 50%;
    max-width: 95px;
    background-color: white;
    border-radius: 50%;
}

.iconsSymbol {
    position: absolute;
    width: $cs-spaces.sextuple;
    height: $cs-spaces.sextuple
}

.iconBackground {
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 50%;
}

.healthInsuranceInfo {
    display: flex;
}

.heathInsurance {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 16px;
    border: 1px solid $cs-slate-200;
    border-radius: $cs-border-radius;
    min-width: 65%;

    @media only screen and (max-width: 768px) {
        min-width: 85%;
    }

    @media only screen and (max-width: 500px) {
        min-width: 100%;
    }


    &--flat {
        border: none;
        padding: 0;
    }

    &__copy {
        flex-direction: column;
        font-size: 16px;
        margin-left: $cs-space;
        height: 95px;
        padding: 15px 15px 15px 30px;
        border-radius: 70px 10px 10px 70px;
        background-color white;

        @media $display-breakpoints.sm-and-up {
            font-size: 20px;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95px;
        width: 95px;
        transform: scale(0.8);
    }

    &__title {
        color: $cs-danger;
        font-weight: 700;
        font-size: 15px;
        display: flex;
        flex-direction column;

        > h6 {
            text-decoration: underline;
            cursor: pointer;
        }

        > span {
            font-weight: normal;
            color: black;
        }

        > a {
            color: black;
            font-weight: normal;
        }
    }

    &__chevron-right {
        margin-top: 2px;
        height: $cs-spaces.onehalf;
        width: $cs-spaces.onehalf;
    }
}
</style>
