<template>
    <div class="serviceCards">
        <div class="header__title header__title--center">
            <h2>{{ title }}</h2>
            <p v-if="subline" class="header__subline">
                {{ subline }}
            </p>
        </div>

        <v-layout class="serviceCards__container" wrap>
            <v-flex
                v-for="(service, index) in services"
                :key="index"
                class="serviceCards__flex"
                xs12
                sm6
                md3
            >
                <v-card
                    class="serviceCards__card csCard"
                    :href="withLink ? service.href : null"
                    :data-test="`service_${index}_link`"
                    :hover="withLink"
                    @click="
                        () =>
                            trackEventForUserActions(
                                'Service Cards',
                                service.title,
                                service.href,
                            )
                    "
                >
                    <DynamicImage
                        :image-name="service.image"
                        :alt="service.title"
                        class="serviceCards__image"
                    />
                    <div class="csCard__content serviceCards__details">
                        <div class="serviceCards__info">
                            <p class="h5">{{ service.title }}</p>

                            <ul class="serviceCards__services hidden-xs-only">
                                <li
                                    v-for="(item, key) in service.service"
                                    :key="key"
                                >
                                    {{ item }}
                                </li>
                            </ul>
                        </div>

                        <p
                            v-if="withLink"
                            class="serviceCards__readmore hidden-xs-only"
                        >
                            {{ readMore }}
                        </p>
                    </div>
                </v-card>
            </v-flex>
        </v-layout>

        <p v-if="helperText" class="serviceCards__helperText text--large">
            {{ helperText }}
        </p>
    </div>
</template>

<script>
import DynamicImage from '@/components/atoms/image/dynamicImage.vue'

import gtmTracking from '@/mixins/gtmTracking'
import MarketingVideo from "~/components/molecules/marketingVideo.vue";

export default {
    name: 'ServiceCard',

    components: {
        DynamicImage,
        MarketingVideo
    },

    mixins: [gtmTracking],

    props: {
        title: {
            type: String,
            default: 'Leistungen unserer Alltagshilfen',
        },

        subline: {
            type: String,
            default:
                'Keine Pauschalpakete – Sie bekommen genau die Unterstützung, die Sie benötigen.',
        },

        withLink: {
            type: Boolean,
            required: false,
            default: true,
        },

        readMore: {
            type: String,
            default: 'Mehr erfahren',
        },

        helperText: {
            type: String,
        },

        services: {
            type: Array,
            validator: value => typeof value === 'object' || !value,
            default: () => [
                {
                    title: 'Begleitung, Einkauf & Erledigungen',
                    image:
                        'services/Fotolia_203255722_Subscription_Monthly_M.jpg',
                    service: ['Terminbegleitung', 'Besorgungen', 'Einkäufe'],
                    href: '/angebot',
                },
                {
                    title: 'Freizeit & Kultur',
                    image: 'services/_SGA6712-Bearbeitet.jpg',
                    service: [
                        'Gemeinsames Kochen',
                        'Freizeitbegleitung',
                        'Ausflüge',
                    ],
                    href: '/angebot',
                },
                {
                    title: 'Gesellschaft leisten & Betreuung',
                    image: 'services/_SGA5268-Bearbeitet.jpg',
                    service: ['Demenzbetreuung', 'Gedächtnisübungen'],
                    href: '/angebot',
                },
                {
                    title: 'Haushaltshilfe',
                    image: 'services/_SGA5691-Bearbeitet.jpg',
                    service: [
                        'Hauswirtschaft',
                        'Gartenarbeit',
                        'Handwerksarbeiten',
                    ],
                    href: '/angebot',
                },
                {
                    title: 'Kleine Grundpflege',
                    image: 'services/commuting-in-train.jpg',
                    service: [
                        'Leichte Körperpflege',
                        'Hilfe bei Nahrungsaufnahme',
                        'Hilfe beim Anziehen',
                    ],
                    href: '/angebot',
                },
            ],
        },
    },
}
</script>

<style lang="stylus">
.serviceCards {

    &__container {
        @media $display-breakpoints.sm-and-up {
            margin: 0 (-($cs-spaces.half));
        }
    }

    &__flex {
        display: flex;
        flex-direction: column;
        flex-basis: 20% !important;

        &:last-child .serviceCards__card.v-card {
            margin-bottom: 0;
        }

        @media $display-breakpoints.md-and-up {
            flex-direction: row;
        }
    }

    &__card.v-card {
        display: flex;
        flex-direction: column;
        margin: 0 0 $cs-spaces.onehalf;
        overflow: hidden;
        position: relative;

        @media $display-breakpoints.sm-and-up {
            margin: $cs-spaces.half;
        }

        @media $display-breakpoints.md-and-up {
            margin: 0 $cs-spaces.half;
        }
    }

    &__image {
        display: block;
        max-height: 300px;
        object-fit: cover;
        width: 100%;
    }

    &__details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        @media $display-breakpoints.xs-only {
            position: absolute;
            bottom: 0;
            height: auto;
            background: rgba($cs-white, 0.9);
            width: 100%;
            text-align: center;
            padding: $cs-space;
        }
    }

    &__services {
        list-style: disc;
        padding-left: $cs-space;
        margin: $cs-spaces.onehalf 0;
    }

    &__readmore {
        color: $cs-primary;
        font-weight: bold;
        margin: 0;
    }

    &__helperText {
        margin: $cs-spaces.double 0 0;

        @media $display-breakpoints.md-and-up {
            margin: $cs-spaces.triple auto 0;
            max-width: 660px;
            text-align: center;
        }
    }
}
</style>
