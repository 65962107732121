export default {
    data() {
        return {
            serviceList: {
                hauswirtschaft: {
                    title: 'Haushaltshilfe',
                    icon: 'cleaning-women',
                },
                'putzen-sauber-machen': {
                    title: 'Putzen & sauber machen',
                    icon: 'cleaning-vacuum',
                },
                'fenster-putzen': {
                    title: 'Fenster putzen',
                    icon: 'window',
                },
                'leichte-hausarbeiten': {
                    title: 'Leichte Hausarbeiten',
                    icon: 'spray-bottle',
                },
                wasche: {
                    title: 'Wäsche',
                    icon: 'ironing',
                },
                korperpflege: {
                    title: 'Körperpflege',
                    icon: 'bathroom-shower-person',
                },
                mobilitatshilfe: {
                    title: 'Mobilitätshilfe',
                    icon: 'disability-walking-help',
                },
                'an-und-auskleiden': {
                    title: 'An- und Auskleiden',
                    icon: 'trousers',
                },
                'hilfe-beim-toilettengang': {
                    title: 'Hilfe beim Toilettengang',
                    icon: 'toilet-seat',
                },
                kochen: {
                    title: 'Kochen',
                    icon: 'appliances-slow-cooker',
                },
                'gemeinsam-essen': {
                    title: 'Gemeinsam essen',
                    icon: 'restaurant-seat',
                },
                'hilfe-beim-essen': {
                    title: 'Hilfe beim Essen',
                    icon: 'breakfast-cereal-bowl-spoon',
                },
                'administrative-aufgaben': {
                    title: 'Administrative Aufgaben',
                    icon: 'pen-write-paper',
                },
                'gesellschaft-leisten': {
                    title: 'Gesellschaft leisten',
                    icon: 'team-chat',
                },
                'hobbys-pflegen': {
                    title: 'Hobbys pflegen',
                    icon: 'card-game-cards',
                },
                'ausfluge-spaziergange': {
                    title: 'Ausflüge & Spaziergänge',
                    icon: 'outdoors-tree-valley',
                },
                einkaufshilfe: {
                    title: 'Einkaufshilfe',
                    icon: 'shopping-cart',
                },
                terminbegleitung: {
                    title: 'Terminbegleitung',
                    icon: 'journey-location',
                },
                'eigenes-auto': {
                    title: 'Eigenes Auto',
                    icon: 'car',
                },
                demenzbetreuung: {
                    title: 'Demenzbetreuung',
                    icon: 'brain-head-heart',
                },
            },
        }
    },

    methods: {
        getService(key) {
            return this.serviceList[key]
        },
    },
}
