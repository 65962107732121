<template>
    <v-form
        ref="form"
        lazy-validation
        :class="[
            'postcodeForm',
            {
                'postcodeForm--compact': !expanded,
                'postcodeForm--extended': $slots.default,
            },
        ]"
        @submit.prevent="submit"
    >
        <slot />
        <div class="postcodeForm__inputContainer">
            <div class="postcodeForm__labeledInput postcodeForm__postcodeInput">
                <label
                    v-show="expanded"
                    class="postcodeForm__label h6"
                    for="input_postcodeSearch_postcode"
                >
                    {{ label }}
                </label>
                <CSTextField
                    :label="expanded ? undefined : label"
                    name="postcode"
                    type="tel"
                    :placeholder="placeholder"
                    :single-line="expanded"
                    class="postcodeForm__input"
                    :value="form.postcode"
                    :on-change="updatePostcode"
                    color="primary"
                />
                <CSTextField
                    name="utm_source"
                    type="hidden"
                    value=""
                    class="d-none"
                />
                <CSTextField
                    name="utm_medium"
                    type="hidden"
                    value=""
                    class="d-none"
                />
                <CSTextField
                    name="utm_campaign"
                    type="hidden"
                    value=""
                    class="d-none"
                />
                <CSTextField
                    name="utm_campaigns"
                    type="hidden"
                    value=""
                    class="d-none"
                />
                <CSTextField
                    name="utm_term"
                    type="hidden"
                    value=""
                    class="d-none"
                />
                <CSTextField
                    name="utm_content"
                    type="hidden"
                    value=""
                    class="d-none"
                />
            </div>
            <CSButton
                :text="expanded ? ctaText : ctaTextShort || ctaText"
                type="submit"
                class="postcodeForm__button"
                color="primary"
                :loading="loading || form.loading"
                large
            />
        </div>
        <p class="postcodeForm__errorMessage">
            {{ form.postcodeErrorMessage }}
        </p>
    </v-form>
</template>

<script>
import CSButton from '@/components/atoms/button/CSButton.vue'
import CSTextField from '@/components/atoms/form/CSTextField.vue'

import FormValidation from '@/mixins/validation'
import { getCaregiversAvailableInPostcode } from '@/src/postcode'

export default {
    name: 'PostcodeForm',

    components: {
        CSButton,
        CSTextField,
    },

    mixins: [FormValidation],

    props: {
        expanded: {
            type: Boolean,
            required: false,
            default: false,
        },
        postcode: {
            required: false,
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Postleitzahl',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'z.B. 10405',
        },
        ctaTextShort: {
            type: String,
            required: false,
            default: undefined,
        },
        ctaText: {
            type: String,
            required: false,
            default: 'Suchen',
        },
        validatePostcode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            form: {
                postcodeErrorMessage: '',
                postcode: '',
                loading: false,
            },
        }
    },

    watch: {
        postcode(newPostcode) {
            this.updatePostcode(newPostcode)
        },
    },

    methods: {
        async getPostcodeErrorMessage(postcode = '') {
            if (postcode.length === 0) {
                return 'Bitte Postleitzahl eingeben'
            }

            if (postcode.length !== 5 || !this.isPostcode(postcode)) {
                return 'Bitte überprüfen Sie Ihre Postleitzahl'
            }

            if (!this.validatePostcode) {
                return ''
            }

            try {
                const { available } = await getCaregiversAvailableInPostcode(
                    postcode,
                )

                if (!available) {
                    return 'In diesem PLZ-Bereich stehen leider noch keine Alltagshelfer zur Verfügung'
                }
            } catch (error) {
                if (error?.response?.data?.error === 'postcode not found') {
                    return 'Bitte überprüfen Sie Ihre Postleitzahl'
                }
            }

            return ''
        },

        updatePostcode(postcode) {
            this.form.postcode = postcode
            this.form.postcodeErrorMessage = ''
        },

        async submit() {
            this.form.loading = true

            this.form.postcodeErrorMessage = await this.getPostcodeErrorMessage(
                this.form.postcode,
            )

            if (this.form.postcodeErrorMessage) {
                this.form.loading = false
                this.$emit(
                    'error',
                    this.form.postcode,
                    this.form.postcodeErrorMessage,
                )

                return
            }

            this.$emit('submit', this.form.postcode)
        },
    },
}
</script>

<style lang="stylus">
.postcodeForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__inputContainer {
        display: flex;
    }

    &__errorMessage {
        font-size: 12px;
        color: $cs-salmon;
        margin-top: -($cs-space);
        text-align: center;
    }

    &:not(.postcodeForm--compact):not(.postcodeForm--extended) &__button {
        @media $display-breakpoints.sm-and-up {
            margin-left: $cs-space;
            margin-top: $cs-spaces.double;
        }
    }

    &__input.v-text-field > .v-input__control > .v-input__slot {
        border-radius: $cs-border-radius;
    }

    &__label {
        font-weight: 700;
        height: $cs-spaces.double;
        display: block;
    }

    &--compact  {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    &--compact &__button {
        margin: 0;

        &.v-btn--round {
            border-radius: 0 $cs-border-radius $cs-border-radius 0;
        }
    }

    &--compact &__input.v-text-field--outline .v-input__slot {
        border-radius: $cs-border-radius 0 0 $cs-border-radius;
        border-color: $cs-primary !important;
    }
}

.postcodeForm--extended {
    flex-wrap: wrap;
    justify-self: stretch;

    @media $display-breakpoints.md-and-up {
        justify-content: space-between;
        flex-shrink: 0;
        justify-self: center;
    }

    @media $display-breakpoints.md-and-up {
        flex-wrap: nowrap;
    }

    .postcodeForm__button {
        margin: $cs-space 0;

        @media $display-breakpoints.md-and-up {
            margin-top: $cs-spaces.double;
        }
    }

    .v-input,
    .postcodeForm__button,
    .postcodeForm__labeledInput {
        flex-basis: 100%;

        @media $display-breakpoints.md-and-up {
            flex-basis: auto;
            margin-right: $cs-spaces.onehalf;

            &:last-child {
                margin-right: 0;
                flex-basis: 100%;
            }
        }

        @media $display-breakpoints.md-and-up {
            &:last-child {
                flex-basis: initial;
            }
        }
    }

    @media $display-breakpoints.md-and-up {
        .postcodeForm__labeledInput {
            max-width: 260px;
        }

        .postcodeForm__postcodeInput {
            max-width: 190px;
        }
    }

    .v-input__slot {
        box-shadow: none !important;
        border-radius: $cs-border-radius !important;
    }

    .v-select__selections {
        color: inherit !important;
    }
}
</style>
