<template>
    <v-layout row wrap class="priceAndFinances">
        <v-flex xs12 md5 class="priceAndFinances__intro section">
            <SVGIcon :icon-name="icon" class="icon__priceAndFinances" />

            <div class="header__title">
                <h2>{{ title }}</h2>

                <p class="header__subline" v-html="subline" />

                <p class="sublineBold">
                    {{ sublineBold }}
                </p>
            </div>

            <p class="priceAndFinances__text">
                {{ text }}
            </p>

            <IconList
                :list="list"
                strong
                icon-color="secondary"
                component-identifier="PriceAndFinances"
            />
        </v-flex>

        <v-flex xs12 md6 offset-md1>
            <DynamicImage
                :image-name="image"
                :alt="title"
                class="priceAndFinances__image"
            />
        </v-flex>
    </v-layout>
</template>

<script>
import CSButton from '@/components/atoms/button/CSButton.vue'
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import DynamicImage from '@/components/atoms/image/dynamicImage.vue'
import ResponsiveImage from '@/components/atoms/image/ResponsiveImage.vue'
import IconList from '@/components/molecules/list/iconList.vue'

import gtmTracking from '@/mixins/gtmTracking'
import {
    BASE_PRICE_PER_HOUR,
    PREFIX_BASE_PRICE_PER_HOUR,
} from '@/data/constants'

export default {
    name: 'PriceAndFinances',
    components: {
        CSButton,
        SVGIcon,
        DynamicImage,
        ResponsiveImage,
        IconList,
    },
    mixins: [gtmTracking],

    props: {
        title: {
            type: String,
            default: 'Preise und Finanzierung',
        },

        subline: {
            type: String,
            default: `Alle Leistungen ${[
                PREFIX_BASE_PRICE_PER_HOUR,
                BASE_PRICE_PER_HOUR,
            ]
                .filter(s => s !== '')
                .join(' ')}&#8239;€/Stunde`,
        },

        sublineBold: {
            type: String,
            default: '1 Stunde kostenfreier Ersttermin',
        },

        text: {
            type: String,
            default:
                'Lernen Sie Ihre Alltagshilfe während Ihres Ersttermins persönlich kennen. Careship schenkt allen Neukundinnen und Neukunden eine Stunde gratis. Wenn die Chemie zwischen Ihnen stimmt und alles passt, buchen Sie nach Ihrem individuellen Bedarf kostenpflichtig weitere Stunden. Über Careship gebuchte Leistungen werden einfach und transparent abgerechnet. Sie haben einen Pflegegrad? Dann nutzen Sie das Pflegegeld oder beantragen Sie bei Ihrer Pflegekasse die Verhinderungspflege (VHP) und lassen Sie sich die Kosten für Careship erstatten!',
        },

        icon: {
            type: String,
            default: 'icon-charge',
        },

        image: {
            type: String,
            default: 'graphics/scene-living-room.png',
        },

        list: {
            type: Array,
            validator: value => typeof value === 'object' || !value,
            default: () => [
                {
                    text: 'Privat zahlen',
                },
                {
                    text: 'Finanzieren über die',
                    link: {
                        text: 'Pflegekasse',
                        url:
                            '/senioren-ratgeber/finanzierung-und-erstattung-von-pflegekosten/',
                    },
                },
            ],
        },
    },
}
</script>

<style lang="stylus">
.priceAndFinances {

    &__intro {
        order: 1;

        @media $display-breakpoints.md-and-up {
            order: 0;
        }
    }

    &__image {
        display: block;
        margin: 0 auto;
        max-width: 400px;
        width: 100%;
    }

    .icon__priceAndFinances {
        height: 32px;
        margin: 0 0 $cs-space;
        width: 32px;
        color: $cs-primary;
    }

    .sublineBold {
        font-weight: 700;
        color: $cs-secondary;
        font-size: 20px;
    }
}
</style>
