<template>
    <div
        :class="{ marketingVideo: true, 'marketingVideo--two-columns': twoColumnsLayout }"
        data-test="marketing-video-main-page"
    >
        <div v-if="!twoColumnsLayout">
            <!-- Single column layout -->
            <video controls class="video" :poster="videoPoster">
                <source :src="videoSource" type="video/mp4" />
            </video>
        </div>
        <div v-else>
            <!-- Two columns layout -->
            <div class="column">
                <div class="title">{{ title }}</div>
            </div>
            <div class="column">
                <video controls class="video">
                    <source :src="videoSource" type="video/mp4" />
                </video>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarketingVideo',
    props: {
        videoSource: { type: String, required: true },
        videoPoster: { type: String, required: true },
        title: { type: String, required: true },
        twoColumnsLayout: { type: Boolean, default: false }
    }
};
</script>

<style lang="stylus">
.marketingVideo {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 16px;
    border: 1px solid $cs-slate-200;
    border-radius: $cs-border-radius;
    margin-top: 20px;
    margin-bottom:10px;
    min-width: 65%;

    @media only screen and (max-width: 768px) {
        min-width: 85%;
    }

    @media only screen and (max-width: 500px) {
        min-width: 100%;
    }

    &--flat {
        border: none;
        padding: 0;
    }

    &__copy {
        flex-direction: column;
        font-size: 16px;
        margin-left: $cs-space;

        @media $display-breakpoints.sm-and-up {
            font-size: 20px;
        }
    }

    &__icon {
        width: $cs-spaces.triple;
        height: $cs-spaces.triple;
    }

    &__title {
        color: $cs-slate-400;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
    }

    &__chevron-right {
        margin-top: 2px;
        height: $cs-spaces.onehalf;
        width: $cs-spaces.onehalf;
    }

    .video {
        width: 100%;
    }

    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        margin-bottom: 20px;
    }
}
</style>
