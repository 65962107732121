<template>
    <v-card
        class="storyQuote csCard"
        :href="displayLink"
        data-test="storyQuote_link"
        :hover="enableLinks"
        @click="
            () =>
                trackEventForUserActions('Customer Stories Quote', title, link)
        "
    >
        <img
            :data-src="require(`@/assets/images/${image}.jpg`)"
            :alt="title"
            class="storyQuote__image lazyload"
        />

        <div class="csCard__content">
            <h5>{{ title }}.</h5>
            <p class="storyQuote__description">
                {{ age }} Jahre, seit
                <span class="storyQuote__since">{{ careshipSince }}</span> bei
                Careship <br />
                {{ profession }}
            </p>
            <p class="storyQuote__quote">
                {{ quote }}
            </p>
        </div>
    </v-card>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'StoryQuoteCard',

    mixins: [gtmTracking],

    props: {
        title: {
            type: String,
            required: true,
        },
        age: {
            type: String,
            required: true,
        },
        careshipSince: {
            type: String,
            required: true,
        },
        profession: {
            type: String,
            required: true,
        },
        quote: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        enableLinks: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        displayLink() {
            return this.enableLinks ? this.link : ''
        },
    },
}
</script>

<style lang="stylus">
.storyQuote {
    height: 100%;

    h5 {
        margin: 0 0 $cs-spaces.half;
    }

    &__image {
        display: block;
        height: 180px;
        object-fit: cover;
        width: 100%;
    }

    &__description {
        font-size: 14px;
        color: $cs-slate-300;
        margin: 0 0 $cs-spaces.onehalf;
    }

    &__since {
        text-transform: uppercase;
    }

    &__quote {
        font-style: italic;
        margin: 0;
        position: relative;
        display: flex;
        font-weight: 500;

        &::before {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39 32'%3E%3Cpath d='M10.1 23.9c1.9-2.3 2.8-4.7 2.8-7.1 0-1-.1-1.9-.4-2.6-1.4 1.1-2.9 1.6-4.6 1.6-2.3 0-4.1-.7-5.7-2.1S0 10.4 0 7.9c0-2.3.8-4.1 2.3-5.7S5.7 0 7.9 0c3.2 0 5.6 1.3 7.3 3.9 1.4 2.2 2.1 4.9 2.1 8.2 0 4.2-1.1 7.9-3.2 11.2s-5.3 5.9-9.6 8l-1-2.3c2.5-1.1 4.8-2.8 6.6-5.1zm21.1 0c1.9-2.3 2.8-4.7 2.8-7.1 0-1-.1-1.9-.4-2.6-1.4 1.1-2.9 1.6-4.6 1.6-2.2 0-4.1-.7-5.6-2.1s-2.3-3.4-2.3-5.8c0-1.5.4-2.8 1.1-4.1s1.7-2.2 2.9-2.8 2.5-1 3.9-1c3.2 0 5.6 1.3 7.3 3.9 1.4 2.2 2.1 4.9 2.1 8.2 0 4.2-1.1 7.9-3.2 11.2s-5.3 5.9-9.6 8L24.5 29c2.6-1.1 4.8-2.8 6.7-5.1z' fill='%23FFAA00'/%3E%3C/svg%3E%0A") no-repeat;
            color: $cs-primary;
            content: ' ';
            display: block;
            flex: 1 0 auto;
            height: $cs-spaces.double;
            margin-right: $cs-spaces.half;
            margin-top: -($cs-spaces.half);
            width: $cs-spaces.double;
        }
    }
}
</style>
