var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{class:[
        'phoneContactCard',
        {
            'phoneContactCard--compact': _vm.compact,
            'phoneContactCard--rounded': _vm.rounded,
        } ],attrs:{"href":("tel:" + _vm.phoneNumber),"data-test":"phoneContact"},on:{"click":function () { return _vm.trackEventForUserActions(
                _vm.componentIdentifier,
                'Phone Number',
                _vm.phoneNumber
            ); }}},[_c('SVGIcon',{staticClass:"icon__phone",attrs:{"icon-name":_vm.icon}}),_vm._v(" "),_c('span',{staticClass:"phoneContactCard__contactInfo"},[_c('p',{staticClass:"phoneContactCard__phoneNumber",attrs:{"data-test":"phoneContact_number"}},[_vm._v("\n            "+_vm._s(_vm.phoneNumber)+"\n        ")]),_vm._v(" "),(_vm.consultingHours)?_c('span',{staticClass:"phoneContactCard__hours",attrs:{"data-test":"phoneContact_hours"}},[_c('ul',_vm._l((_vm.splitHours(_vm.consultingHours)),function(hour,index){return _c('li',{key:index},[_vm._v("\n                    "+_vm._s(hour)+"\n                ")])}),0)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }