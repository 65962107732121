<template>
    <div class="caregiverQuality">
        <SVGIcon icon-name="award" class="icon__caregiverQuality" />

        <div class="caregiverQuality__content">
            <h3 class="caregiverQuality__title">{{ title }}</h3>

            <ul class="caregiverQuality__list">
                <li
                    v-for="(item, index) in list"
                    :key="index"
                    class="caregiverQuality__item"
                >
                    {{ item }}
                </li>
            </ul>

            <CSLink
                v-if="Object.keys(link).length && withLink"
                :data-test="`caregiverQuality_link`"
                :href="link.href"
                :external="link.external"
                class="caregiverQuality__link"
                @click="
                    () =>
                        trackEventForUserActions(
                            'Caregiver Quality',
                            link.text,
                            link.href,
                        )
                "
            >
                {{ link.text }}
            </CSLink>
        </div>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import CSLink from '@/components/atoms/link/CSLink.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'CaregiverQuality',

    components: {
        SVGIcon,
        CSLink,
    },

    mixins: [gtmTracking],

    props: {
        title: {
            type: String,
            default: 'Geprüft, qualifiziert, engagiert ',
        },
        list: {
            type: Array,
            validator: value => value.every(v => typeof v === 'string'),
            default: () => [
                'Mehrstufiger Bewerbungsprozess mit Interview und Schulung',
                'sauberes Führungszeugnis',
                'haftpflichtversichert',
                'Erfahrung im Umgang mit Seniorinnen & Senioren',
                'soziales Engagement',
            ],
        },
        link: {
            type: Object,
            default: () => ({
                text: 'Mehr erfahren',
                href: '/unsere-betreuer/',
                external: true,
            }),
        },
        withLink: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
}
</script>

<style lang="stylus">
.caregiverQuality {

    @media $display-breakpoints.sm-and-up {
        display: flex;
        margin: 0 auto;
        max-width: 520px;
    }

    &__title {
        margin: 0 0 $cs-spaces.half;
    }

    &__list {
        margin: 0;
        list-style: disc;
        padding-left: $cs-space;
    }

    &__link {
        display: inline-block;
        margin: $cs-spaces.onehalf 0 0;
    }
}

.icon__caregiverQuality {
    color: $cs-primary;
    width: 40px;
    height: 50px;
    margin: 0 0 $cs-space;

    @media $display-breakpoints.sm-and-up {
        margin-right: $cs-spaces.double;
        flex: 0 0 auto;
    }
}
</style>
