<template>
    <div class="timelineYear">
        <span v-if="label" class="timelineYear__label">
            {{ label }}
        </span>
        <div>
            <p class="timelineYear__desc">
                <strong>{{ header }}</strong>
                {{ text }}
            </p>
            <CSLink
                v-if="button"
                :data-test="`timelineYear_${label}_link`"
                :href="button.href"
                :external="button.external"
                class="timelineYear__link"
                @click="
                    () =>
                        trackEventForUserActions(
                            'Timeline',
                            button.text,
                            button.href,
                        )
                "
            >
                {{ button.text }}
            </CSLink>
        </div>
    </div>
</template>

<script>
import gtmTracking from '@/mixins/gtmTracking'
import CSLink from '@/components/atoms/link/CSLink.vue'

export default {
    name: 'TimelineYear',

    components: {
        CSLink,
    },

    mixins: [gtmTracking],

    props: {
        label: {
            type: String,
            required: true,
        },
        header: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        button: {
            required: false,
            validator: value => typeof value === 'object' || !value,
            default: null,
        },
    },
}
</script>

<style lang="stylus">
.timelineYear {
    display: flex;

    &__label {
        font-size: 18px;
        font-weight: 700;
        margin: 0 $cs-space 0 0;

        @media $display-breakpoints.sm-and-up {
            margin: 0 $cs-spaces.onehalf 0 0;
        }
    }

    &__desc {
        display: block;
        margin: 0;
    }

    &__link {
        display: inline-block;
        margin: $cs-spaces.onehalf 0 0;
    }
}
</style>
