<template lang="html">
    <div class="dialogWindow__container" data-test="dialogWindow">
        <div class="dialogWindow__closeIcon">
            <CSButton
                data-test="dialogWindow_close_btn"
                fab
                depressed
                icon
                small
                :round="false"
                color="white"
                @click="emitClose"
            >
                <SVGIcon icon-name="cross" class="icon__close" />
            </CSButton>
        </div>

        <SVGIcon v-if="icon" :icon-name="icon" class="icon__dialogWindow" />

        <h3 v-if="title" class="dialogWindow__title">
            {{ title }}
        </h3>

        <p v-if="content" class="dialogWindow__content">
            {{ content }}
        </p>

        <slot></slot>
    </div>
</template>

<script>
import CSButton from '@/components/atoms/button/CSButton.vue'
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'DialogWindow',

    components: {
        CSButton,
        SVGIcon,
    },

    mixins: [gtmTracking],

    props: {
        title: {
            type: String,
        },

        icon: {
            type: String,
        },

        content: {
            type: String,
        },

        componentIdentifier: {
            type: String,
            required: true,
        },
    },

    methods: {
        emitClose() {
            this.trackEventForUserActions(
                this.componentIdentifier,
                'Close Dialog Window',
                '',
            )

            this.$emit('closeDialog')
        },
    },
}
</script>

<style lang="stylus">
.dialogWindow {
    background: $cs-white;
    border-radius: $cs-border-radius;

    &__container {
        padding: $cs-spaces.onehalf $cs-spaces.onehalf $cs-spaces.triple;
        position: relative;
    }

    &__title {
        margin: $cs-spaces.onehalf 0 $cs-spaces.half;
    }

    &__closeIcon {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__content {
        margin: 0;
    }

    .icon__close {
        height: $cs-spaces.onehalf;
        width: $cs-spaces.onehalf;
    }

    .icon__dialogWindow {
        height: 56px;
        width: 56px;
    }
}
</style>
