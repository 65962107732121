<template>
    <ul
        :class="[
            'iconList',
            {
                'iconList--center': center,
                'iconList--large': large,
                'iconList--strong': strong,
            },
        ]"
    >
        <li v-for="(list, key) in list" :key="key">
            <SVGIcon
                :icon-name="list.icon || 'circle-check'"
                :class="['icon__list', { [iconColor + '--text']: iconColor }]"
            />
            <span class="iconList__listItem">
                {{ list.text || list }}

                <CSLink
                    v-if="list.link"
                    :data-test="`iconList_${key}_link`"
                    :href="list.link.url"
                    :external="true"
                    class="iconList__link"
                    @click="
                        () =>
                            trackEventForUserActions(
                                componentIdentifier,
                                list.link.text,
                                list.link.url,
                            )
                    "
                >
                    {{ list.link.text }}
                </CSLink>
            </span>
        </li>
    </ul>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import CSLink from '@/components/atoms/link/CSLink.vue'
import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'IconList',

    components: {
        SVGIcon,
        CSLink,
    },

    mixins: [gtmTracking],

    props: {
        list: {
            type: Array,
            required: true,
        },

        large: {
            type: Boolean,
        },

        center: {
            type: Boolean,
        },

        strong: {
            type: Boolean,
        },

        iconColor: {
            type: String,
        },

        componentIdentifier: {
            type: String,
        },
    },
}
</script>

<style lang="stylus">
.iconList {

    li {
        align-items: flex-start;
        display: flex;
        margin: $cs-space 0;

        &:last-child {
            margin: 0;
        }
    }

    &--strong {
        font-weight: 800;
    }

    &--center li {
        align-items: center;
    }

    &--large li {
        margin: $cs-spaces.onehalf 0;
    }

    &__listItem {
        margin: 0 0 0 $cs-space;
    }

    &__link {
        display: inline-block;
    }
}

.icon__list {
    color: $cs-primary;
    display: block;
    flex: 0 0 auto;
    height: 22px;
    width: 22px;

     .iconList--large & {
        height: 32px;
        width: 32px;
    }
}
</style>
