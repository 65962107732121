<template lang="html">
    <div class="homepage">
        <HeroBookingBanner :hero-banner="heroBanner" />

        <v-container class="container--slim">
            <MediaTrust class="section homepage__mediaTrust" />

            <UspSection
                class="section section--dividerTop"
                v-bind="USPSection"
            />

            <div class="homepage__coronaInfoContainer">
              <!-- <CoronaInfo /> Requested to be removed in ticket FULF-5199 -->
                <Info45b />
            </div>

        </v-container>

        <v-container class="container--slim">
            <MarketingVideo
                :two-columns-layout="marketingVideo.twoColumnsLayout"
                :title="marketingVideo.title"
                :video-source="marketingVideo.videoSource"
                :video-poster="marketingVideo.videoPoster"
            />
        </v-container>

        <section class="section--slim section--slate050">
            <v-container class="container--slim">
                <Services class="section" v-bind="serviceCards" />

                <TrustIcons
                    class="section"
                    :title="trustIcon.title"
                    two-rows
                    large
                />
            </v-container>
        </section>

        <v-container class="container--slim ">
            <FindCaregivers
                class="section"
                v-bind="findCaregivers"
                numbering
                center
                component-identifier="Find A Caregiver"
            />

            <div class="section">
                <NeedMoreInfo />
            </div>
        </v-container>

        <section class="section section--primary">
            <v-container class="container--slim">
                <Quote v-bind="quote" />
            </v-container>
        </section>

        <v-container class="container--slim">
            <PriceAndFinances />
        </v-container>

        <section class="section section--slate050">
            <v-container class="container--slim">
                <CustomerStoriesCards v-bind="customerStoryQuote" />

                <v-divider class="section--divider" />

                <CaregiverQuality />
            </v-container>
        </section>

        <v-container class="container--slim">
            <AboutUs />
        </v-container>

        <Prefooter>
            <template v-slot:left>
                <RelatedArticle
                    :article="relatedArticle"
                    :splash="splashes.newLocations.forCareGivers"
                />
            </template>
            <template v-slot:right>
                <PostcodeSearch :splash="splashes.newLocations.forCareTakers" />
            </template>
        </Prefooter>
    </div>
</template>

<script>
import MediaTrust from '@/components/organisms/list/mediaTrustList.vue'
import TrustIcons from '@/components/molecules/trustedIcons/TrustedIcons.vue'
import UspSection from '@/components/organisms/UspSection.vue'
import Quote from '@/components/molecules/quote.vue'
import Services from '@/components/organisms/services/serviceCards.vue'
import FindCaregivers from '@/components/organisms/list/listThreeColumns.vue'
import NeedMoreInfo from '@/components/organisms/needMoreInformation.vue'
import PriceAndFinances from '@/components/organisms/PriceAndFinances.vue'
import CustomerStoriesCards from '@/components/organisms/CustomerStories/cardStory.vue'
import CaregiverQuality from '@/components/organisms/caregiverQuality.vue'
import AboutUs from '@/components/organisms/aboutUs.vue'
import Prefooter from '@/components/organisms/footer/Prefooter.vue'
import PostcodeSearch from '@/components/molecules/prefooter/postcodeSearch.vue'
import RelatedArticle from '@/components/molecules/prefooter/relatedArticle.vue'
import CoronaInfo from '@/components/molecules/coronaInfo.vue'
import Info45b from '@/components/molecules/info45b'
import HeroBookingBanner from '@/components/organisms/heroBanner/booking.vue'

import {
    BASE_PRICE_PER_HOUR,
    PREFIX_BASE_PRICE_PER_HOUR,
} from '@/data/constants'

import { customerStoryQuote } from '@/data/customerStories'
import MarketingVideo from "~/components/molecules/marketingVideo.vue";

export default {
    name: 'Willkommen',
    path: 'willkommen',
    layout: 'native',

    components: {
        MarketingVideo,
        Info45b,
        MediaTrust,
        HeroBookingBanner,
        UspSection,
        Services,
        FindCaregivers,
        NeedMoreInfo,
        Quote,
        PriceAndFinances,
        CustomerStoriesCards,
        CaregiverQuality,
        AboutUs,
        Prefooter,
        PostcodeSearch,
        RelatedArticle,
        TrustIcons,
        CoronaInfo,
    },

    mounted()
    {
        this.saveQueryParameters();
    },
    methods: {
        saveQueryParameters() {
            sessionStorage.setItem('utm_content', (this.$route.query.utm_content && typeof(this.$route.query.utm_content)!='undefined')?this.$route.query.utm_content: (sessionStorage.getItem('utm_content')?sessionStorage.getItem('utm_content'):''));
            sessionStorage.setItem('utm_medium', (this.$route.query.utm_medium && typeof(this.$route.query.utm_medium)!='undefined')?this.$route.query.utm_medium: (sessionStorage.getItem('utm_medium')?sessionStorage.getItem('utm_medium'):''));
            sessionStorage.setItem('utm_campaign',(this.$route.query.utm_campaign && typeof(this.$route.query.utm_campaign)!='undefined')?this.$route.query.utm_campaign: (sessionStorage.getItem('utm_campaign')?sessionStorage.getItem('utm_campaign'):''));
            sessionStorage.setItem('utm_campaigns',(this.$route.query.utm_campaigns && typeof(this.$route.query.utm_campaigns)!='undefined')?this.$route.query.utm_campaigns: (sessionStorage.getItem('utm_campaigns')?sessionStorage.getItem('utm_campaigns'):''));
            sessionStorage.setItem('utm_source', (this.$route.query.utm_source && typeof(this.$route.query.utm_source)!='undefined')?this.$route.query.utm_source: (sessionStorage.getItem('utm_source')?sessionStorage.getItem('utm_source'):''));
            sessionStorage.setItem('utm_term', (this.$route.query.utm_term && typeof(this.$route.query.utm_term)!='undefined')?this.$route.query.utm_term: (sessionStorage.getItem('utm_term')?sessionStorage.getItem('utm_term'):''));
            sessionStorage.setItem('gclid', (this.$route.query.gclid && typeof(this.$route.query.gclid)!='undefined')?this.$route.query.gclid: (sessionStorage.getItem('gclid')?sessionStorage.getItem('gclid'):'default'));
        }
    },
    data: () => ({
        marketingVideo:{
            title:'',
            twoColumnsLayout: false,
            videoSource: '/app/uploads/2024/03/video_1280.mp4',
            videoPoster: '/app/uploads/2024/03/careship_video_poster_final.png',
        },
        heroBanner: {
            headerShort: 'Alltagshelfer aus Ihrer Nachbarschaft',
            headerExtended:
                'Alltagshilfe aus Ihrer Nachbarschaft für unterstützungsbedürftige Menschen',
            image: 'heroBanner/_SGA3672-Bearbeitet.jpg',
            pricePerHour: BASE_PRICE_PER_HOUR,
            pricePerHourPrefix: PREFIX_BASE_PRICE_PER_HOUR,
        },
        findCaregivers: {
            ctaText: 'Jetzt Alltagshilfe finden',
        },
        relatedArticle: {
            title: 'Sie möchten Alltagshilfe werden?',
            text:
                'Unterstützen Sie Seniorinnen & Senioren in allem, was den Alltag erleichtert.',
            ctaText: 'Als Alltagshilfe bewerben',
            ctaLink: '/kunde/alltagshilfe/bewerbung',
            image: 'services/Angehoerigenpflege_126539398_L',
        },
        USPSection: {
            title: 'Das Besondere an uns',
            subline: '',
            uspList: [
                {
                    icon: 'icon-charge',
                    text: 'Finanzierbar über die Pflegekasse',
                    linkTo: '/preise/pflegekasse',
                },
                {
                    icon: 'touch-id-user',
                    text: 'Immer dieselbe Alltagshilfe',
                },
                {
                    icon: 'business-deal-cash-exchange',
                    text: 'Flexible Buchung nach Bedarf',
                },
                {
                    icon: 'calendar-clock',
                    text: 'Alltagshilfe in 24 Std. verfügbar',
                },
                {
                    icon: 'shield-lock',
                    text: 'Geprüft und haftpflichtversichert',
                },
            ],
        },
        serviceCards: {
            helperText:
                'Unsere Alltagshilfen kümmern sich genau um die Dinge, die Sie sich wünschen und die Sie zum Wohlfühlen brauchen. Mit viel Einfühlungsvermögen bieten sie ihre Unterstützung in allen Bereichen des Alltags an.',
        },
        trustIcon: {
            title: 'Alle Alltagshilfen sind versichert durch:',
        },
        quote: {
            quote:
                'Ich weiß, dass jemand kommt, wenn ich alleine nicht klar komme.',
            image: 'quotes/customer_thomsen.jpg',
            author: 'Frau Thomson aus Hamburg',
            readMore: 'Mehr lesen',
            link: '/senioren-ratgeber/kundengeschichte-frau-t/',
            componentIdentifier: 'Willkommen customer quote',
        },
        splashes: {
            newLocations: {
                forCareGivers: {
                    message: 'Jetzt in Berlin auch in Festanstellung',
                    href: '/senioren-ratgeber/alltagshelfer-festanstellung/',
                    large: 'true',
                },
                forCareTakers: {
                    message:
                        'Neu: In Berlin dauerhaft über die Pflegekasse abrechenbar',
                    href: '/senioren-ratgeber/alltagshilfe-abrechnen/',
                    large: 'true',
                },
            },
        },
        customerStoryQuote,
    }),

    head() {
        return {
            title: 'Seniorenbetreuung | Alltagshelfer aus der Nachbarschaft',
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content:
                        'Seniorenbetreuung gesucht? Careship unterstützt Senioren im Alltag und entlastet pflegende Angehörige. Finden Sie in nur 24 Stunden einen passenden Alltagshelfer aus der Nachbarschaft! Verfügbar in Berlin und Hamburg!',
                },
            ],
        }
    },
}
</script>

<style lang="stylus">
.homepage {
    overflow: hidden;

    &__mediaTrust {
        margin-top: $cs-space;
    }

    &__coronaInfoContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: $cs-spaces.quadruple;
    }
}
</style>
