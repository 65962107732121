<template>
    <div class="quote">
        <q class="quote__phrase" :cite="link">{{ quote }}</q>
        <div class="quote__avatar">
            <ResponsiveImage
                :image-name="image"
                class="quote__avatarImg"
                alt="author"
            />
        </div>
        <p class="quote__subline">
            <span class="quote__author">{{ author }}</span>
            <CSLink
                v-if="withLink"
                external
                data-test="link"
                class="quote__readMore"
                :href="link"
                @click="handleClick"
            >
                {{ readMore }}
            </CSLink>
        </p>
    </div>
</template>

<script>
import ResponsiveImage from '@/components/atoms/image/ResponsiveImage.vue'
import CSLink from '@/components/atoms/link/CSLink.vue'
import gtmTracking from '@/mixins/gtmTracking'

export default {
    components: {
        CSLink,
        ResponsiveImage,
    },
    mixins: [gtmTracking],
    props: {
        quote: {
            type: String,
            required: false,
            default:
                'Ich weiß, dass jemand kommt, wenn ich alleine nicht klar komme.',
        },
        image: {
            type: String,
            required: false,
            default: 'quotes/customer_thomsen.jpg',
        },
        author: {
            type: String,
            required: false,
            default: 'Frau Thomson aus Hamburg',
        },
        readMore: {
            type: String,
            required: false,
            default: 'Mehr lesen',
        },
        link: {
            type: String,
            required: false,
            default: '/senioren-ratgeber/kundengeschichte-frau-t/',
        },
        componentIdentifier: {
            type: String,
            required: false,
            default: 'customer quote',
        },
        withLink: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    methods: {
        handleClick() {
            this.trackEventForUserActions(
                this.componentIdentifier,
                `User clicked link (${this.readMore})`,
                this.link,
            )
        },
    },
}
</script>

<style lang="stylus">
.quote {
    align-items: center;
    max-width: 860px;
    margin: 0 auto;
    text-align: center;

    @media $display-breakpoints.sm-and-up {
        display: grid;
        grid-template-columns: 150px + $cs-spaces.quintuple 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: 'avatar content' 'avatar subline';
        text-align: left;
        padding-left: $cs-spaces.triple;
        padding-right: $cs-spaces.triple;
    }
}

.quote__avatar {
    grid-area: avatar;
    margin: $cs-spaces.double 0;
    align-self: start;

    @media $display-breakpoints.sm-and-up {
        margin: 0;
    }
}

.quote__avatarImg {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 100%;

    @media $display-breakpoints.sm-and-up {
        width: 135px;
        height: 135px;
    }

    @media $display-breakpoints.md-and-up {
        width: 150px;
        height: 150px;
    }
}

.quote__phrase {
    grid-area: content;
    color: $cs-white;
    font-size: 32px;
    font-style: italic;
    position: relative;
    display: block;

    &::before {
        content: ' ';
        display: block;
        margin: 0 auto;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39 32'%3E%3Cpath d='M10.1 23.9c1.9-2.3 2.8-4.7 2.8-7.1 0-1-.1-1.9-.4-2.6-1.4 1.1-2.9 1.6-4.6 1.6-2.3 0-4.1-.7-5.7-2.1S0 10.4 0 7.9c0-2.3.8-4.1 2.3-5.7S5.7 0 7.9 0c3.2 0 5.6 1.3 7.3 3.9 1.4 2.2 2.1 4.9 2.1 8.2 0 4.2-1.1 7.9-3.2 11.2s-5.3 5.9-9.6 8l-1-2.3c2.5-1.1 4.8-2.8 6.6-5.1zm21.1 0c1.9-2.3 2.8-4.7 2.8-7.1 0-1-.1-1.9-.4-2.6-1.4 1.1-2.9 1.6-4.6 1.6-2.2 0-4.1-.7-5.6-2.1s-2.3-3.4-2.3-5.8c0-1.5.4-2.8 1.1-4.1s1.7-2.2 2.9-2.8 2.5-1 3.9-1c3.2 0 5.6 1.3 7.3 3.9 1.4 2.2 2.1 4.9 2.1 8.2 0 4.2-1.1 7.9-3.2 11.2s-5.3 5.9-9.6 8L24.5 29c2.6-1.1 4.8-2.8 6.7-5.1z' fill='%23fff'/%3E%3C/svg%3E%0A") no-repeat;
        width: $cs-spaces.triple;
        height: $cs-spaces.triple;

        @media $display-breakpoints.sm-and-up {
            position: absolute;
            left: -($cs-spaces.quadruple);
            top: -($cs-spaces.quarter);
        }
    }

    &::after {
        content: '';
    }
}

.quote__subline {
    grid-area: subline;
    margin-top: $cs-space;
    align-self: flex-start;
}

.quote__left {
    flex-grow: 0;
}

.quote__readMore {
    color: $cs-white;
    text-decoration: none;
    font-weight: bold;
    display: block;
    margin-top: $cs-spaces.half;

    @media $display-breakpoints.md-and-up {
        display: inline;
        margin-left: $cs-space;
        margin-top: 0;
    }
}
</style>
