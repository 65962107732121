<template>
    <div
        v-if="Boolean(pricePerHourPrefix)"
        :class="[
            'priceSplash',
            'priceSplash--prefixed',
            { 'priceSplash--large': large },
        ]"
    >
        <p class="priceSplash__content">
            <span class="priceSplash__prefix">{{ pricePerHourPrefix }}</span>
            <span class="priceSplash__price">{{ pricePerHour }}</span>
            <span class="priceSplash__per-hour">€/Std.</span>
        </p>
    </div>
    <div v-else :class="['priceSplash', { 'priceSplash--large': large }]">
        <p class="priceSplash__content">
            <span class="priceSplash__currency">€</span>
            <span class="priceSplash__price">{{ pricePerHour }}</span>
            <span class="priceSplash__hour">/Std.</span>
        </p>
    </div>
</template>

<script>
export default {
    name: 'PriceSplash',
    props: {
        pricePerHourPrefix: {
            type: String,
            default: '',
        },
        pricePerHour: {
            type: String,
            required: true,
        },
        large: {
            type: Boolean,
        },
    },
}
</script>

<style lang="stylus">
.priceSplash {
    color: $cs-white;
    background-color: $cs-secondary;
    width: $cs-spaces.sextuple;
    height: $cs-spaces.sextuple;
    border-radius: $cs-border-radius-round;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
        margin: 0 0 $cs-spaces.half 6px;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        font-weight: 500;
    }
    &__prefix {
        font-size: 14px;
    }
    &__currency {
        font-size: 14px;
        left: 0;
        position: absolute;
        top: -2px;
    }
    &__price {
        font-size: 48px;
        font-weight: 800;
        line-height: 45px;
        margin-right: 2px;
    }
    &__hour {
        bottom: -12px;
        display: block;
        font-size: 12px;
        position: absolute;
        right: 12px;
        text-align: right;
    }
    &__per-hour {
        display: block;
        font-size: 12px;
    }
    &--prefixed {
        .priceSplash__content {
            margin: 0 0 $cs-spaces.half 0;
        }
        .priceSplash__price {
            line-height: 40px;
        }
    }
    &--large {
        .priceSplash__prefix {
            font-size: 24px;
            margin: 0;
        }
        .priceSplash__per-hour {
            bottom: -($cs-space);
            font-size: 16px;
        }
        width: $cs-spaces.s1000;
        height: $cs-spaces.s1000;
        .priceSplash__currency {
            font-size: 20px;
            left: -3px;
            top: -3px;
        }
        .priceSplash__price {
            font-size: 68px;
            line-height: 64px;
            margin: 0;
        }
        .priceSplash__hour {
            bottom: -($cs-space);
            font-size: 16px;
        }
    }
}
</style>
