<template>
    <div
        class="coronaInfo"
        :class="{ 'coronaInfo--flat': flat }"
        data-test="corona-info"
        @click="handleClick()"
    >
        <SVGIcon icon-name="corona-against" class="coronaInfo__icon" />

        <div class="coronaInfo__copy">
            <div class="coronaInfo__title">
                Informationen zu Corona
                <SVGIcon
                    class="coronaInfo__chevron-right"
                    icon-name="chevron-right"
                />
            </div>
            <div>
                Wir lassen Sie nicht allein.
            </div>
        </div>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

export default {
    name: 'CoronaInfo',

    components: {
        SVGIcon,
    },

    props: {
        flat: Boolean,
    },

    methods: {
        handleClick() {
            if (process.client) {
                window.location.href = '/senioren-ratgeber/covid/'
            }
        },
    },
}
</script>

<style lang="stylus">
.coronaInfo {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 16px;
    border: 1px solid $cs-slate-200;
    border-radius: $cs-border-radius;
    min-width: 65%;

    @media only screen and (max-width: 768px) {
        min-width: 85%;
    }

    @media only screen and (max-width: 500px) {
        min-width: 100%;
    }


    &--flat {
        border: none;
        padding: 0;
    }

    &__copy {
        flex-direction: column;
        font-size: 16px;
        margin-left: $cs-space;

        @media $display-breakpoints.sm-and-up {
            font-size: 20px;
        }
    }

    &__icon {
        width: $cs-spaces.triple;
        height: $cs-spaces.triple;
    }

    &__title {
        color: $cs-danger;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
    }

    &__chevron-right {
        margin-top: 2px;
        height: $cs-spaces.onehalf;
        width: $cs-spaces.onehalf;
    }
}
</style>
