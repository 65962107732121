<template>
    <div class="heroBooking" data-testid="hero">
        <ResponsiveImage
            :image-name="heroBanner.image"
            class="heroBooking__image"
        />
        <div class="heroBooking__gradient" />
        <v-container class="heroBooking__container container--slim">
            <div
                :class="[
                    'heroBooking__col',
                    { 'heroBooking__col--attached': !largePriceSplash },
                ]"
            >
                <router-link
                    to="/preise"
                    :class="[
                        'heroBooking__splash',
                        {
                            'heroBooking__splash--attached': !largePriceSplash,
                        },
                    ]"
                >
                    <PriceSplash
                        :price-per-hour="heroBanner.pricePerHour"
                        :price-per-hour-prefix="heroBanner.pricePerHourPrefix"
                        :large="largePriceSplash"
                    />
                </router-link>
                <div class="heroBooking__heathInsurance hidden-sm-and-up">
                    <HealthInsuranceInfo />
                </div>

                <div class="heroBooking__header">
                    <h1>
                        {{ showHeader }}
                    </h1>
                    <p v-if="heroBanner.subline" class="header__subline">
                        {{ heroBanner.subline }}
                    </p>
                </div>

                <TrustIcons
                    v-if="showTrustIcons"
                    class="heroBooking__trustIcons"
                    :align="$vuetify.breakpoint.smAndUp ? 'left' : 'center'"
                />

                <div class="heroBooking__heathInsurance hidden-xs-only">
                    <HealthInsuranceInfo />
                </div>

                <div class="heroBooking__form">
                    <PostcodeForm
                        class="heroBooking__postcode"
                        :label="
                            $vuetify.breakpoint.smAndUp
                                ? $t('LABEL_POSTCODE_WHERE')
                                : undefined
                        "
                        :cta-text="ctaText"
                        :expanded="hasMounted && $vuetify.breakpoint.smAndUp"
                        @submit="postcodeSubmitted"
                        :validate-postcode="true"
                    />
                    <PhoneContractCard
                        v-if="showPhoneNumber"
                        class="heroBooking__phoneNumber"
                        phone-number="(030) 120 822 342"
                        component-identifier="Herobanner - Postcode Search"
                        compact
                    />
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import ResponsiveImage from '@/components/atoms/image/ResponsiveImage.vue'
import TrustIcons from '@/components/molecules/trustedIcons/TrustedIcons.vue'
import PostcodeForm from '@/components/molecules/form/postcodeForm.vue'
import PhoneContractCard from '@/components/molecules/consultancyCall/phoneContactCard.vue'
import PriceSplash from '@/components/molecules/priceSplash.vue'
import HealthInsuranceInfo from '@/components/molecules/healthInsurance'
import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'HeroBookingBanner',

    components: {
        PriceSplash,
        PostcodeForm,
        PhoneContractCard,
        ResponsiveImage,
        TrustIcons,
        HealthInsuranceInfo,
    },

    mixins: [gtmTracking],

    props: {
        heroBanner: {
            type: Object,
            required: true,
        },

        showTrustIcons: {
            type: Boolean,
            default: false,
        },

        showPhoneNumber: {
            type: Boolean,
            default: false,
        },

        largePriceSplash: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        registrationURL: '/kunde/registrieren',
        hasMounted: false,
    }),

    computed: {
        ctaText() {
            return this.hasMounted && this.$vuetify.breakpoint.smAndUp
                ? this.$t('BUTTON_POSTCODESEARCH_SEARCH')
                : this.$t('LABEL_FIND')
        },
        showHeader() {
            if (
                this.heroBanner.headerExtended &&
                this.$vuetify.breakpoint.smAndUp
            ) {
                return this.heroBanner.headerExtended
            }

            return this.heroBanner.headerShort
        },
    },

    mounted() {
        this.hasMounted = true
    },

    methods: {
        postcodeSubmitted(postcode) {
            const message = postcode

            this.trackEventForUserActions(
                'Herobanner - Postcode Search',
                this.ctaText,
                message,
            )
            const mainLink = `/kunde/registrieren`;

            const queryString = window.location.search === ''?`?postcode=${postcode}`: window.location.search + `&postcode=${postcode}`
            window.location.href = `${mainLink}${queryString}`;
        },
    },
}
</script>

<style lang="stylus">
.heroBooking {
    padding-bottom: $cs-space;
    position: relative;

    @media $display-breakpoints.xs-only {
        padding-top: $cs-spaces.quadruple;
    }

    &__container {
        position: relative;
    }

    &__col {
        background: $cs-white;
        border-radius: $cs-spaces.quarter;
        box-shadow: $cs-box-shadow-card;
        display: flex;
        flex-direction: column;
        margin-top: $cs-spaces.s1000;
        max-width: 488px;

        @media $display-breakpoints.sm-and-up {
            background: none;
            border-radius: 0;
            box-shadow: none;
            margin: 0;
        }

        &--attached {
            position: relative;
        }
    }

    &__header {
        margin: $cs-space;
        position: relative;
        z-index: 1;

        @media $display-breakpoints.sm-and-up {
            margin: $cs-spaces.quintuple 0 0;
            max-width: 330px;
        }

        @media $display-breakpoints.md-and-up {
            max-width: 470px;
        }

        @media $display-breakpoints.lg-and-up {
            margin-top: $cs-spaces.sextuple;
            max-width: 100%;
        }
    }

    .header__subline {
        @media $display-breakpoints.sm-and-up {
            margin-right: $cs-spaces.triple;
        }
    }

    &__heathInsurance {
        display: flex;
        margin-top: $cs-spaces.half;
        padding-bottom: $cs-spaces.half;
        padding-left: $cs-space;
        border-bottom: 1px solid $cs-slate-100;
        width: 100%;

        @media $display-breakpoints.sm-and-up {
            margin-top: $cs-spaces.onehalf;
            padding-left: 0;
            padding-bottom: 0;
            border-bottom: none;
            width: auto;
        }
    }

    &__form {
        background: $cs-white;

        @media $display-breakpoints.sm-and-up {
            align-self: stretch;
            border-radius: $cs-spaces.quarter;
            box-shadow: $cs-box-shadow-card;
            margin: $cs-spaces.double 0 0
            order: 3;
            position: relative;
        }
    }

    &__postcode {
        padding: $cs-spaces.half $cs-space 0;

        @media $display-breakpoints.sm-and-up {
            border-radius: $cs-spaces.quarter;
            padding: $cs-spaces.double $cs-spaces.double $cs-spaces.half;
        }
    }

    &__phoneNumber.phoneContactCard {
        color: $cs-primary;

        .icon__phone {
            height: 32px;
            margin-right: $cs-space;
            width: 32px;
        }
    }

    &__splash {
        position: absolute;
        right: 0;
        top: -($cs-spaces.quintuple);
        transform: translateX($cs-spaces.onehalf);
        transition: ease-in-out all 200ms;
        z-index: 2;
        text-decoration: none;

        @media $display-breakpoints.sm-and-up {
            top: auto;
            bottom: 0;
            transform: translateX(-($cs-space));
        }

        &--attached {
            @media $display-breakpoints.sm-and-up {
                margin-bottom: -($cs-spaces.quadruple);
                margin-left: auto;
                margin-right: -($cs-spaces.onehalf);
                margin-top: -($cs-spaces.double);
                order: 3;
                position: relative;
                top: 0;
            }
        }
    }

    &__trustIcons {
        @media $display-breakpoints.xs-only {
            background: $cs-slate-050;
            border-radius: 0 0 $cs-spaces.quarter $cs-spaces.quarter;
            justify-content: center;
            order: 4;
            padding: $cs-spaces.half $cs-space;

            &>:last-child {
                margin-right: 0;
            }
        }

        @media $display-breakpoints.md-and-up {
            margin-bottom: $cs-spaces.half;
            margin-top: $cs-space;
        }

        @media $display-breakpoints.lg-and-up {
            margin-bottom: 48px;
        }
    }

    &__image {
        border-bottom: 4px solid $cs-primary;
        box-sizing: content-box;
        display: block;
        object-fit: cover;
        object-position: right center;

        @media $display-breakpoints.sm-and-up {
            object-position: center top ;
        }

        @media $display-breakpoints.lg-and-up {
            object-position: center 15%;
        }
    }

    &__image,
    &__gradient {
        height: "calc(100% - %s)" % $cs-spaces.sextuple;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @media $display-breakpoints.sm-and-up {
            height: "calc(100% - %s)" % $cs-spaces.triple;
        }
    }

    &__gradient {
        @media $display-breakpoints.sm-and-up {
            background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 34%, rgba(255,255,255,0.7)  100%);
            min-width: 380px;
            width: 65%;
        }
    }
}
</style>
