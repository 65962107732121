<template>
    <div class="customerStoriesCard">
        <div class="header__title header__title--center">
            <h2>{{ title }}</h2>
            <p class="header__subline" v-html="subline" />
        </div>

        <vue-glide :options="glideOptions" :bullet="true">
            <vue-glide-slide
                v-for="(story, index) in stories"
                :key="index"
                class="customerStoryCard"
            >
                <component
                    :is="contentComponent"
                    v-bind="story"
                    :enable-links="enableLinks"
                />
            </vue-glide-slide>

            <template slot="control">
                <button
                    data-glide-dir="<"
                    class="glide__nav glide__nav--previous"
                >
                    <SVGIcon icon-name="chevron-left" class="icon__nav" />
                </button>
                <button data-glide-dir=">" class="glide__nav glide__nav--next">
                    <SVGIcon icon-name="chevron-right" class="icon__nav" />
                </button>
            </template>
        </vue-glide>
    </div>
</template>

<script>
import { Glide, GlideSlide } from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import ServicesCard from '@/components/molecules/customerStories/storyServicesCard.vue'
import QuoteCard from '@/components/molecules/customerStories/storyQuoteCard.vue'

export default {
    name: 'CustomerStoriesCards',

    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
        SVGIcon,
        ServicesCard,
        QuoteCard,
    },

    props: {
        userStories: Object,
        title: {
            type: String,
            default: 'Kundengeschichten',
        },
        subline: {
            type: String,
            default:
                'Im Durchschnitt beanspruchen unsere Kunden unsere Leistungen zwei Stunden die Woche (ab 56&#8239;€)',
        },
        contentComponent: {
            type: String,
            default: 'ServicesCard',
        },
        enableLinks: {
            type: Boolean,
            default: true,
        },
        stories: {
            type: Array,
            default: () => [
                {
                    title: 'Herr B. & Marvin',
                    price: '2 Std. / Woche',
                    link: '/senioren-ratgeber/kundengeschichte-herr-b',
                    image: 'userStories/userStories-1',
                    services: [
                        'mobilitatshilfe',
                        'hauswirtschaft',
                        'eigenes-auto',
                        'gesellschaft-leisten',
                        'kochen',
                    ],
                },
                {
                    title: 'Herr K. & Sidney',
                    price: '4 Std. / Woche',
                    link: '/senioren-ratgeber/kundengeschichte-herr-k',
                    image: 'userStories/userStories-2',
                    services: [
                        'hauswirtschaft',
                        'eigenes-auto',
                        'gesellschaft-leisten',
                        'fenster-putzen',
                    ],
                },
                {
                    title: 'Frau S. & Christian',
                    price: '4 Std. / Woche',
                    link: '/senioren-ratgeber/kundengeschichte-frau-s',
                    image: 'userStories/userStories-3',
                    services: [
                        'hauswirtschaft',
                        'gesellschaft-leisten',
                        'fenster-putzen',
                    ],
                },
                {
                    title: 'Herr D. & Bärbel ',
                    price: '3 Std. / Woche',
                    link: '/senioren-ratgeber/kundengeschichte-herr-d',
                    image: 'userStories/userStories-4',
                    services: [
                        'gemeinsam-essen',
                        'hauswirtschaft',
                        'gesellschaft-leisten',
                        'fenster-putzen',
                    ],
                },
                {
                    title: 'Herr I. & Kristina ',
                    price: '6 Std. / Woche',
                    link: '/senioren-ratgeber/kundengeschichte-herr-i',
                    image: 'userStories/userStories-5',
                    services: [
                        'hauswirtschaft',
                        'an-und-auskleiden',
                        'gesellschaft-leisten',
                        'hobbys-pflegen',
                    ],
                },
                {
                    title: 'Herr W. & Markus',
                    price: '4 Std. / Woche',
                    link: '/senioren-ratgeber/kundengeschichte-familie-w',
                    image: 'userStories/userStories-6',
                    services: [
                        'hauswirtschaft',
                        'gesellschaft-leisten',
                        'fenster-putzen',
                        'hobbys-pflegen',
                    ],
                },
            ],
        },
    },

    data: () => ({
        glideOptions: {
            type: 'carousel',
            startAt: 0,
            perView: 3,
            gap: 0,
            breakpoints: {
                960: {
                    perView: 2,
                },
                600: {
                    perView: 1,
                },
            },
        },
    }),
}
</script>

<style lang="stylus">
.customerStoriesCard {
    .glide__bullet {
        background: $cs-white;
        border-radius: $cs-border-radius-round;
        border: 3px solid $cs-slate-200;
        cursor: pointer;
        display: inline-block;
        height: 14px;
        margin: 0 $cs-spaces.quarter;
        outline: 0;
        width: 14px;

        &--active {
            background: $cs-slate-200;
        }
    }

    .glide__bullets {
        text-align: center;
    }

    .glide {
        margin-top: -($cs-space);
    }
}
</style>

<style lang="stylus" scoped>
.glide__nav {
    top: "calc(50% - %s)" % $cs-spaces.double;
    display: block;
    position: absolute;
    outline: 0;

    .icon__nav {
        color: $cs-primary;
        transition: color ease-in-out 200ms;
        display: block;
        height: $cs-spaces.triple;
        width: $cs-spaces.triple;
    }

    &:hover .icon__nav {
        color: $cs-slate-300;
    }

    &--previous {
        left: -($cs-spaces.half);
    }

    &--next {
        right: -($cs-spaces.half);
    }

    @media $display-breakpoints.sm-only {
        &--previous {
            left: -($cs-spaces.half);
        }

        &--next {
            right: -($cs-spaces.half);
        }
    }

    @media $display-breakpoints.md-and-up {
        &--previous {
            left: -($cs-spaces.double);
        }

        &--next {
            right: -($cs-spaces.double);
        }
    }
}

.customerStoryCard {
    display: flex;
    flex-direction: column;

    &.glide__slide {
        height: auto;
        padding: $cs-space $cs-spaces.double $cs-spaces.double;
        margin: 0;

        @media $display-breakpoints.md-and-up {
            padding: $cs-space $cs-spaces.onehalf $cs-spaces.double;
        }
    }
}
</style>
