<template>
    <div class="postcodeSearch">
        <ResponsiveImage
            :image-name="dataPostcodeSearch.image"
            class="postcodeSearch__image"
            small-width
        />

        <div v-if="Boolean(splash)" class="floatSplash">
            <Splash
                :href="splash.href"
                :message="splash.message"
                :large="splash.large"
            />
        </div>

        <div class="postcodeSearch__body">
            <p class="h4">{{ dataPostcodeSearch.title }}</p>
            <span class="postcodeSearch__text">{{
                dataPostcodeSearch.text
            }}</span>
            <PostcodeForm @submit="postcodeSubmitted" />
        </div>
    </div>
</template>

<script>
import PostcodeForm from '@/components/molecules/form/postcodeForm.vue'
import ResponsiveImage from '@/components/atoms/image/ResponsiveImage.vue'
import Splash from '@/components/atoms/splash'

import FormValidation from '@/mixins/validation'
import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'PostcodeSearch',

    components: {
        PostcodeForm,
        ResponsiveImage,
        Splash,
    },

    mixins: [FormValidation, gtmTracking],

    props: {
        postcodeSearch: {
            type: Object,
            default: {},
        },
        splash: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        dataPostcodeSearch: {

            title: 'Alltagshilfe finden',
            text:
                'Ihre Postleitzahl eingeben und sehen, welche Alltagshilfe in Ihrer Nachbarschaft verfügbar ist.',
            image: 'heroBanner/_SGA7011_v2',
            registrationURL: '/kunde/registrieren',
        },
    }),

    created() {
        this.dataPostcodeSearch = Object.assign(
            this.dataPostcodeSearch,
            this.postcodeSearch,
        )
    },

    methods: {
        postcodeSubmitted(postcode) {
            this.trackEventForUserActions(
                'Prefooter - Postcode Search',
                'Submit postcode to registration',
                postcode,
            )
            const mainLink = `/kunde/registrieren`;

            const queryString = window.location.search === ''?`?postcode=${postcode}`: window.location.search + `&postcode=${postcode}`
            window.location.href = `${mainLink}${queryString}`;
        },
    },
}
</script>

<style lang="stylus">
.postcodeSearch {
    position: relative;

    &__image {
        border-radius: $cs-border-radius;
        height: 200px;
        object-fit: cover;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media $display-breakpoints.lg-and-up {
            height: 250px;
        }
    }

    &__body {
        max-width: 400px;
    }

    .h4 {
        margin: $cs-spaces.onehalf 0 $cs-spaces.half;
    }

    &__text {
        display: inline-block;
        margin: 0 0 $cs-spaces.half;
    }

    .postcodeForm {
        margin: $cs-space 0 0;
    }

    .floatSplash {
        position: absolute;
        top: 30%;
        right: -25px;
    }
}
</style>
