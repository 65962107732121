<template>
    <component
        :is="external ? 'a' : 'nuxt-link'"
        :to="href"
        :href="href"
        class="csLink"
        @click="e => $emit('click', e)"
    >
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: 'CSLink',

    props: {
        external: {
            type: Boolean,
            required: false,
            default: false,
        },
        href: {
            type: String,
            required: true,
            validator: value =>
                value.match(/^(https?:\/\/.+|tel:\/\/.+|\/.*|#.+)/),
        },
    },
}
</script>

<style lang="stylus">
.csLink {
    border-bottom: 1px solid transparent;
    font-weight: bold;
    text-decoration: none;
    transition: ease-out 200ms border-color;

    &:hover {
        border-color: currentColor;
    }
}
</style>
