var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['timeline', { 'timeline--hasImages': _vm.hasImages }]},_vm._l((_vm.timeline),function(item,i){return _c('div',{key:i,class:[
            'timeline__item',
            {
                'timeline__item--dashed': item.dashed,
                'timeline__item--highlight': item.highlight,
                'timeline__item--wide': item.wide,
                'timeline__item--banner': item.banner,
            } ]},[(item.image)?_c('DynamicImage',{class:[
                'timeline__image',
                { 'timeline__image--wide': item.wide } ],attrs:{"image-name":item.image}}):_vm._e(),_vm._v(" "),(item.banner)?_c('div',{class:['banner_container']},[(item.contentComponent && item.banner)?_c(item.contentComponent,_vm._b({tag:"component"},'component',item.contentProps,false)):_vm._e()],1):_vm._e(),_vm._v(" "),(item.title)?_c('h2',{staticClass:"header__title"},[_vm._v("\n            "+_vm._s(item.title)+"\n        ")]):_vm._e(),_vm._v(" "),(!item.wide && !item.banner)?_c('div',{staticClass:"timeline__body"},[_c('div',{class:[
                    'timeline__dot',
                    { 'timeline__dot--icon': item.highlight } ]},[(item.highlight)?_c('SVGIcon',{staticClass:"icon__highlight",attrs:{"icon-name":item.icon}}):_vm._e()],1),_vm._v(" "),(item.contentComponent && !item.banner)?_c(item.contentComponent,_vm._b({tag:"component"},'component',item.contentProps,false)):_vm._e()],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }