<template>
    <section class="section section--slate050">
        <v-container class="container--slim">
            <v-layout wrap class="preFooter">
                <v-flex xs12 sm5 md4 offset-md1>
                    <slot name="left"></slot>
                </v-flex>

                <v-flex sm1 class="hidden-xs-and-down">
                    <v-divider
                        vertical
                        inset
                        class="preFooter__divider hidden-print-only"
                    />
                </v-flex>

                <v-flex xs12 sm6 md5>
                    <slot name="right"></slot>
                </v-flex>
            </v-layout>
        </v-container>
    </section>
</template>

<script>
export default {
    name: 'Prefooter',
}
</script>

<style lang="stylus">
.preFooter {
    &__divider.v-divider--vertical {
        color: $cs-slate-200;
        display: block;
        height: 100%;
        margin: 0 auto;
        max-height: 100%;
    }
}
</style>
