const customerStoryQuote = {
    title: 'Wer sind unsere Alltagshilfen?',
    subline: 'Engagierte Menschen, denen Sie am Herzen liegen.',
    contentComponent: 'QuoteCard',
    stories: [
        {
            title: 'Marvin H',
            age: '29',
            careshipSince: 'JUN 2018',
            profession: 'Student der Zeitgeschichte',
            quote:
                'Ich möchte angesichts des akuten Pflegenotstandes meinen Beitrag zur Verbesserung unserer immer kälter werdenden Gesellschaft leisten.',
            link: '/senioren-ratgeber/kundengeschichte-herr-b',
            image: 'userStories/userStories-1',
        },
        {
            title: 'Bärbel K',
            age: '69',
            careshipSince: 'JUN 2018',
            profession: 'Selbstständige Werbewirtschaftlerin',
            quote:
                'Ehrenamtlich bin ich als Lese-Lernhilfe in einer Grundschule einmal wöchentlich tätig. Ich unterstütze gern auch ältere Menschen im täglichen Leben.',
            link: '/senioren-ratgeber/kundengeschichte-herr-d',
            image: 'userStories/userStories-4',
        },
        {
            title: 'Sidney V',
            age: '29',
            careshipSince: 'DEZ 2017',
            profession: 'Betreuer',
            quote:
                'Mein Ziel ist es, Situationen zu schaffen, nach denen beide Seiten innerlich lächeln und sagen können: ‚Ja, so ist das Leben für mich positiver!',
            link: '/senioren-ratgeber/kundengeschichte-herr-k',
            image: 'userStories/userStories-2',
        },
        {
            title: 'Christian S',
            age: '31',
            careshipSince: 'AUG 2018',
            profession: 'Tätig im Gastronomiewesen',
            quote:
                'Ich finde, dass man, egal wie alt oder jung man ist, immer etwas von dem anderen lernen kann, und Lebenserfahrung kann man nie genug haben.',
            link: '/senioren-ratgeber/kundengeschichte-frau-s',
            image: 'userStories/userStories-3',
        },
        {
            title: 'Kristina D',
            age: '33',
            careshipSince: 'OKT 2018',
            profession: 'Kaufmännische Angestellte',
            quote:
                'Ich bin Alltagshelferin, weil es ein Herzenswunsch ist, älteren Menschen bei den Dingen zu helfen, die sie vielleicht selbst nicht mehr schaffen.',
            link: '/senioren-ratgeber/kundengeschichte-herr-i',
            image: 'userStories/userStories-5',
        },
        {
            title: 'Markus F',
            age: '74',
            careshipSince: 'SEP 2016',
            profession: 'Musiker, Betreuer, Rentner',
            quote:
                'Ich versuche mit Humor ein wenig Leichtigkeit in das Leben meiner Mitmenschen zu bringen und habe für alle Probleme ein offenes Ohr.',
            link: '/senioren-ratgeber/kundengeschichte-familie-w',
            image: 'userStories/userStories-6',
        },
    ],
}

export { customerStoryQuote }
