var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heroBooking",attrs:{"data-testid":"hero"}},[_c('ResponsiveImage',{staticClass:"heroBooking__image",attrs:{"image-name":_vm.heroBanner.image}}),_vm._v(" "),_c('div',{staticClass:"heroBooking__gradient"}),_vm._v(" "),_c('v-container',{staticClass:"heroBooking__container container--slim"},[_c('div',{class:[
                'heroBooking__col',
                { 'heroBooking__col--attached': !_vm.largePriceSplash } ]},[_c('router-link',{class:[
                    'heroBooking__splash',
                    {
                        'heroBooking__splash--attached': !_vm.largePriceSplash,
                    } ],attrs:{"to":"/preise"}},[_c('PriceSplash',{attrs:{"price-per-hour":_vm.heroBanner.pricePerHour,"price-per-hour-prefix":_vm.heroBanner.pricePerHourPrefix,"large":_vm.largePriceSplash}})],1),_vm._v(" "),_c('div',{staticClass:"heroBooking__heathInsurance hidden-sm-and-up"},[_c('HealthInsuranceInfo')],1),_vm._v(" "),_c('div',{staticClass:"heroBooking__header"},[_c('h1',[_vm._v("\n                    "+_vm._s(_vm.showHeader)+"\n                ")]),_vm._v(" "),(_vm.heroBanner.subline)?_c('p',{staticClass:"header__subline"},[_vm._v("\n                    "+_vm._s(_vm.heroBanner.subline)+"\n                ")]):_vm._e()]),_vm._v(" "),(_vm.showTrustIcons)?_c('TrustIcons',{staticClass:"heroBooking__trustIcons",attrs:{"align":_vm.$vuetify.breakpoint.smAndUp ? 'left' : 'center'}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"heroBooking__heathInsurance hidden-xs-only"},[_c('HealthInsuranceInfo')],1),_vm._v(" "),_c('div',{staticClass:"heroBooking__form"},[_c('PostcodeForm',{staticClass:"heroBooking__postcode",attrs:{"label":_vm.$vuetify.breakpoint.smAndUp
                            ? _vm.$t('LABEL_POSTCODE_WHERE')
                            : undefined,"cta-text":_vm.ctaText,"expanded":_vm.hasMounted && _vm.$vuetify.breakpoint.smAndUp,"validate-postcode":true},on:{"submit":_vm.postcodeSubmitted}}),_vm._v(" "),(_vm.showPhoneNumber)?_c('PhoneContractCard',{staticClass:"heroBooking__phoneNumber",attrs:{"phone-number":"(030) 120 822 342","component-identifier":"Herobanner - Postcode Search","compact":""}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }