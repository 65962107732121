var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:[
        'iconList',
        {
            'iconList--center': _vm.center,
            'iconList--large': _vm.large,
            'iconList--strong': _vm.strong,
        } ]},_vm._l((_vm.list),function(list,key){
    var _obj;
return _c('li',{key:key},[_c('SVGIcon',{class:['icon__list', ( _obj = {}, _obj[_vm.iconColor + '--text'] = _vm.iconColor, _obj )],attrs:{"icon-name":list.icon || 'circle-check'}}),_vm._v(" "),_c('span',{staticClass:"iconList__listItem"},[_vm._v("\n            "+_vm._s(list.text || list)+"\n\n            "),(list.link)?_c('CSLink',{staticClass:"iconList__link",attrs:{"data-test":("iconList_" + key + "_link"),"href":list.link.url,"external":true},on:{"click":function () { return _vm.trackEventForUserActions(
                            _vm.componentIdentifier,
                            list.link.text,
                            list.link.url
                        ); }}},[_vm._v("\n                "+_vm._s(list.link.text)+"\n            ")]):_vm._e()],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }