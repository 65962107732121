<template>
    <a
        :href="href"
        :class="['splash', 'splash--prefixed', { 'splash--large': large }]"
    >
        <div
            v-if="Boolean(message)"
            :class="['splash', 'splash--prefixed', { 'splash--large': large }]"
        >
            <p class="splash__content">
                <span class="splash__message">{{ message }}</span>
            </p>
        </div>
        <div v-else :class="['splash', { 'splash--large': large }]">
            <p class="splash__content">
                <span class="splash__message">{{ message }}</span>
            </p>
        </div>
    </a>
</template>

<script>
export default {
    name: 'Splash',
    props: {
        message: {
            type: String,
            default: '',
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
        large: {
            type: Boolean,
        },
    },
}
</script>

<style lang="stylus">
a {
    text-decoration: none !important;
}

.splash {
    color: $cs-white;
    background-color: $cs-alert;
    width: $cs-spaces.sextuple;
    height: $cs-spaces.sextuple;
    border-radius: $cs-border-radius-round;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
        margin: 0 0 $cs-spaces.half 6px;
        padding-left: 10px;
        padding-right: 10px;
        position: relative;
        font-weight: 500;
    }
    &__message {
        color: white;
        font-size: 14px;
        font-weight: normal;
    }
    &--prefixed {
        .splash__content {
            margin: 0 0 $cs-spaces.half 0;
        }
    }
    &--large {
        .splash__message {
            font-size: 14px;
            margin: 0;
        }
        width: 160px;
        height: 160px;
    }
}
</style>
