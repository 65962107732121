<template>
    <div
        :class="[
            'trustedIcons',
            `trustedIcons--${align}`,
            { 'trustedIcons--large': large, 'trustedIcons--twoRows': twoRows },
        ]"
    >
        <span class="trustedIcons__title">
            {{ title }}
        </span>

        <SVGIcon
            icon-name="insured-by"
            class="icon__trusted icon__trusted--insured"
        />
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

export default {
    name: 'TrustIcons',

    components: {
        SVGIcon,
    },

    props: {
        title: {
            type: String,
            default: 'Versichert durch',
        },
        align: {
            type: String,
            validator(value) {
                // The value must match one of these strings
                return ['center', 'left', 'evenly'].indexOf(value) !== -1
            },
            default: 'center',
        },
        large: {
            type: Boolean,
            default: false,
        },
        twoRows: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="stylus">
.trustedIcons {
    align-items: center;
    display: flex;

    &__title {
        width: 112px;
        font-size: 12px;
    }

    .icon__trusted {
        flex: 1 0 auto;
        height: 100%;
        max-height: 40px;
        max-width: 62px;
        width: 100%;
        margin-left: $cs-spaces.half;
        margin-right: $cs-spaces.half;

        @media $display-breakpoints.sm-and-up {
            max-height 48px;
        }

        &--certified {
            max-width: 48px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--large .icon__trusted {
        max-height: 64px;
        max-width: 82px;

        &--certified {
            height: 64px;
            max-width: 64px;
        }
    }

    &--twoRows {
        flex-flow: wrap;

        .trustedIcons__title  {
            font-size: 16px;
            text-align: center;
            margin-bottom: $cs-space;
            width: 100%;
        }
    }

    &--left {
        justify-content: flex-start;
    }

    &--center {
        justify-content: center;
    }

    &--evenly {
        justify-content: space-evenly;
    }
}
</style>
