<template>
    <v-layout wrap class="aboutUs">
        <v-flex xs12 lg5 class="section aboutUs__content">
            <h2 class="header__title">{{ dataAboutUs.title }}</h2>
            <Timeline
                class="aboutUs__timeline"
                :timeline="dataAboutUs.timeline"
            />
        </v-flex>
        <v-flex xs12 lg6 offset-lg1 class="aboutUs__imgBlock">
            <DynamicImage
                :image-name="dataAboutUs.image"
                :alt="dataAboutUs.title"
                class="aboutUs__img lazyLoad"
            />
            <div class="aboutUs__quote text--large">
                {{ dataAboutUs.quote }}
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
import DynamicImage from '@/components/atoms/image/dynamicImage.vue'
import Timeline from '@/components/organisms/timeline.vue'
import TimelineYear from '@/components/molecules/timeline/timelineYear.vue'

export default {
    name: 'AboutUs',

    components: {
        DynamicImage,
        Timeline,
    },

    props: {
        aboutUs: {
            type: Object,
            required: false,
        },
    },

    data: () => ({
        dataAboutUs: {
            title: 'Wer ist Careship?',
            quote:
                '“Wir wollten, dass unsere Großmutter so lange wie möglich selbstbestimmt im eigenen Zuhause bleiben kann.”',
            image: 'careship/Omelie_mit-Nikolaus-und-Antonia.jpg',
            timeline: [
                {
                    contentComponent: TimelineYear,
                    contentProps: {
                        label: '2015',
                        header: 'Careship Gründung',
                        text:
                            'durch die Geschwister Antonia und Nikolaus Albert, nachdem sie für ihre Großmutter vergeblich nach individueller Unterstützung suchten.',
                    },
                },
                {
                    contentComponent: TimelineYear,
                    contentProps: {
                        label: '2019',
                        header: 'Bereits über 4000 Familien',
                        text:
                            'haben die Leistungen von Careship in Anspruch genommen und werden von über 1.500 Careship Alltagshilfe unterstützt.',
                    },
                },
                {
                    contentComponent: TimelineYear,
                    contentProps: {
                        label: '2023',
                        header:
                            'Careship erweitert das Serviceangebot',
                        text:
                            'durch Careship Ambulant und bildet Alltagshilfen über die Online-Schulungsplattform Careship Academy aus und weiter.',
                        button: {
                            external: true,
                            href: '/ueber-uns/',
                            text: 'Mehr erfahren',
                        },
                    },
                },
            ],
        },
    }),
}
</script>

<style lang="stylus">
.aboutUs {
    margin: 0 (-($cs-space));

    @media $display-breakpoints.md-and-up {
        margin: 0;
    }

    &__content {
        margin-left: $cs-space;
        margin-right: $cs-space;

        @media $display-breakpoints.md-and-up {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__timeline {
        margin: 0;
    }

    &__imgBlock {
        position: relative;
        order: -1
        width: 100%;

        @media $display-breakpoints.lg-and-up {
            order: 0;
        }
    }

    &__img {
        display: block;
        height: 300px;
        object-fit: cover;
        object-position: top;
        width: 100%;

        @media $display-breakpoints.sm-and-up {
            height: 100%;
            max-height: 440px;
        }

        @media $display-breakpoints.lg-and-up {
            max-height: none;
        }
    }

    &__quote {
        background: linear-gradient(-180deg, rgba($cs-black, 0) 0%, rgb($cs-black) 100%);
        bottom: 0;
        color: $cs-white;
        font-weight: 700;
        padding: $cs-space;
        position: absolute;
        text-align: center;
        width: 100%;

        @media $display-breakpoints.sm-and-up {
            padding: $cs-spaces.double $cs-spaces.triple $cs-space;
        }
    }

}
</style>
