<template>
    <v-card
        class="storyCard csCard"
        :href="displayLink"
        data-test="storyCard_link"
        :hover="enableLinks"
        @click="
            () => trackEventForUserActions('Customer Stories Card', title, link)
        "
    >
        <div class="storyCard__top">
            <img
                :data-src="require(`@/assets/images/${image}.jpg`)"
                :alt="title"
                class="storyCard__image lazyload"
            />
            <div class="storyCard__description">
                <h5>{{ title }}</h5>
                <span>{{ price }}</span>
            </div>
        </div>
        <div class="csCard__content">
            <span class="storyCard__label">{{ serviceTitle }}</span>

            <div
                v-for="(service, index) in services"
                :key="index"
                class="storyCard__chip"
            >
                <SVGIcon
                    :icon-name="getService(service).icon"
                    class="icon__chip"
                />
                {{ getService(service).title }}
            </div>
        </div>
    </v-card>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import ServiceList from '@/mixins/serviceList'
import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'StoryServicesCard',

    components: {
        SVGIcon,
    },

    mixins: [gtmTracking, ServiceList],

    props: {
        title: {
            type: String,
            required: true,
        },
        price: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        services: {
            type: Array,
            required: true,
            validator: value => value.every(v => typeof v === 'string'),
            default: null,
        },
        serviceTitle: {
            type: String,
            default: 'Leistungen bezogen',
        },
        enableLinks: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        displayLink() {
            return this.enableLinks ? this.link : ''
        },
    },
}
</script>

<style lang="stylus">
.storyCard {
    height: 100%;

    &__top {
        flex: 1 0 auto;
        overflow: hidden;
        position: relative;

        p {
            margin: 0;
        }
    }

    &__description {
        background: linear-gradient(to bottom, rgba($cs-black, 0), rgba($cs-black, 0.5));
        color: $cs-white;
        display: flex;
        flex-direction: column;
        height: 180px;
        justify-content: flex-end;
        padding: $cs-space;
        position: relative;
        z-index: 1;

        h5 {
            color: $cs-white;
            margin: 0 0 $cs-spaces.half;
        }
    }

    &__image {
        bottom: 0;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        width: 100%;
        z-index: 0;
    }

    &__label {
        display: block;
        margin: 0 0 $cs-space;
    }

    &__chip {
        align-items: center;
        background: $cs-slate-050;
        border-radius: $cs-border-radius;
        cursor: default;
        display: inline-flex;
        margin: $cs-spaces.quarter;
        padding: $cs-spaces.quarter $cs-spaces.half;
        transition: ease-in-out 200ms all;
        vertical-align: middle;
    }

    .icon__chip {
        flex: 0 0 auto;
        height: 24px;
        margin-right: $cs-spaces.half;
        width: 24px;
    }
}
</style>
