<template>
    <div :class="['listThreeColumns', { 'listThreeColumns--center': center }]">
        <div class="header__title header__title--center">
            <h2>{{ title }}</h2>
            <p v-if="subline" class="header__subline">
                {{ subline }}
            </p>
        </div>

        <v-layout wrap>
            <v-flex
                v-for="(step, index) in list"
                :key="index"
                class="listThreeColumns__list"
                xs12
                sm4
            >
                <DynamicImage
                    v-if="step.image"
                    :image-name="step.image"
                    class="listThreeColumns__image"
                    :alt="step.header"
                />

                <SVGIcon
                    v-if="step.icon"
                    :icon-name="step.icon"
                    class="icon__listThreeColumns"
                />

                <div class="listThreeColumns__item">
                    <div v-if="numbering" class="listThreeColumns__itemNumber">
                        {{ index + 1 }}
                    </div>
                    <div>
                        <p class="h5" v-html="step.header" />
                        {{ step.text }}
                    </div>
                </div>
            </v-flex>
        </v-layout>

        <div v-if="showPriceTables">
            <div class="prices-container">
                <div class="tableContainer">
                    <div class="table">
                        <div class="table__header">
                            <div class="table__mainTitle table__borderBottom">
                                Careship
                            </div>
                            <div
                                class="table__columnHeaders table__row table__borderBottom table__font_heavy"
                            >
                                <div class="table__col1 table__centre">
                                    Standorte
                                </div>
                                <div
                                    class="table__borderLeft table__col2 table__centre"
                                >
                                    Preise
                                </div>
                            </div>
                        </div>
                        <div class="table__row table__borderBottom">
                            <div class="table__col1">
                                Deutschland <br />(exkl. München)
                            </div>
                            <div class="table__borderLeft table__col2">
                                <ul class="with_disk">
                                    <li>Privatzahler: <b>28 € / Std.</b></li>
                                    <li>
                                        Ab Pflegegrad 2 Erstattung durch die
                                        Pflegekasse möglich
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="table__row">
                            <div class="table__col1">
                                München <br />(höhere Personalkosten)
                            </div>
                            <div class="table__borderLeft table__col2">
                                <ul class="with_disk">
                                    <li>Privatzahler: <b>33 € / Std.</b></li>
                                    <li>
                                        Ab Pflegegrad 2 Erstattung durch die
                                        Pflegekasse möglich
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tableContainer">
                    <div class="table">
                        <div class="table__header">
                            <div class="table__mainTitle table__borderBottom">
                                Careship Ambulant
                            </div>
                            <div
                                class="table__columnHeaders table__row table__borderBottom table__font_heavy"
                            >
                                <div
                                    class="table__col1 table__font_heavy table__centre"
                                >
                                    Standorte
                                </div>
                                <div
                                    class="table__borderLeft table__col2 table__font_heavy table__centre"
                                >
                                    Preise
                                </div>
                            </div>
                        </div>
                        <div class="table__row">
                            <div class="table__col1">
                                Berlin<br />
                                (bald in weiteren Regionen verfügbar)
                            </div>
                            <div class="table__borderLeft table__col2">
                                <ul class="with_disk">
                                    <li>Privatzahler: <b>34 € / Std.</b></li>
                                    <li>
                                        Ab Pflegegrad 1 direkte Abrechnung und
                                        Erstattung durch die Pflegekasse möglich
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="withCtaPostcode" class="listThreeColumns__ctaPostcode">
            <PostcodeForm
                :label="ctaPostcodeLabel"
                :placeholder="ctaPostcodePlaceholder"
                :cta-text="ctaPostcodeCtaText"
                @submit="handlePostcodeSearch"
            />
        </div>
        <div v-else-if="ctaText" class="listThreeColumns__cta">
            <CSButton
                data-test="listThreeColumns_cta"
                :text="ctaText"
                :href="ctaLink"
                :outline="buttonOutline"
                @click="
                    () => {
                        $emit('emitToParent')
                        trackEventForUserActions(
                            componentIdentifier,
                            ctaText,
                            ctaLink,
                        )
                    }
                "
            />
        </div>
    </div>
</template>

<script>
import CSButton from '@/components/atoms/button/CSButton.vue'
import DynamicImage from '@/components/atoms/image/dynamicImage.vue'
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import PostcodeForm from '@/components/molecules/form/postcodeForm.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    name: 'ListThreeColumns',

    components: {
        CSButton,
        DynamicImage,
        SVGIcon,
        PostcodeForm,
    },

    mixins: [gtmTracking],

    props: {
        center: {
            type: Boolean,
        },

        numbering: {
            type: Boolean,
        },

        buttonOutline: {
            type: Boolean,
        },

        componentIdentifier: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            default: 'In 3 Schritten zur Alltagshilfe',
        },

        subline: {
            type: String,
            default: 'Vermittlung innerhalb von 24 Stunden möglich',
        },

        ctaText: {
            type: String,
            default: 'Alltagshilfe finden',
        },

        ctaLink: {
            type: String,
            default: '/kunde/',
        },

        onClick: {
            type: Function,
            required: false,
            default: () => {
                return true
            },
        },

        withCtaPostcode: {
            type: Boolean,
            required: false,
        },

        ctaPostcodeLabel: {
            type: String,
            required: false,
            default: 'Postleitzahl',
        },

        ctaPostcodePlaceholder: {
            type: String,
            required: false,
            default: 'z.B. 10405',
        },

        ctaPostcodeCtaText: {
            type: String,
            required: false,
            default: 'Suchen',
        },

        list: {
            type: Array,
            validator: value => value.every(v => typeof v === 'object'),
            default: () => [
                {
                    image: 'graphics/find-home-helpers-1-find-caregiver.png',
                    header: 'Alltagshilfe <br> finden',
                    text:
                        'Nachdem Sie uns einige Informationen hinterlassen haben, starten wir die Suche nach Ihrer passenden Alltagshilfe.',
                },
                {
                    image: 'graphics/find-home-helpers-2-meet-caregiver.png',
                    header: '1 Stunde kostenfreier <br> Kennenlerntermin',
                    text:
                        'Ihre Alltagshilfe wird Sie innerhalb von 24 Stunden kontaktieren und einen Ersttermin mit Ihnen vereinbaren.',
                },
                {
                    image: 'graphics/find-home-helpers-3-enjoy-service.png',
                    header: 'Unterstützung <br> genießen',
                    text:
                        'Egal ob regelmäßig oder gelegentlich, Ihre zuverlässige Alltagshilfe unterstützt Sie flexibel und nach Ihren Bedürfnissen.',
                },
            ],
        },

        showPriceTables: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        handlePostcodeSearch(postcode) {
            const mainLink = `/kunde/registrieren`;

            const queryString = window.location.search === ''?`?postcode=${postcode}`: window.location.search + `&postcode=${postcode}`
            window.location.href = `${mainLink}${queryString}`;
            //window.location.href = `/kunde/registrieren?postcode=${postcode}`
        },
    },
}
</script>

<style lang="stylus">
.listThreeColumns {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;

    &--center {
        text-align: center;
    }

    &__ctaPostcode {
        margin: $cs-spaces.triple 0 0;
    }

    &__cta {
        text-align: center;
        margin: $cs-spaces.triple 0 0;

        .v-btn {
            width: 100%;
            margin: 0;

            @media $display-breakpoints.sm-and-up {
                width: auto;
            }
        }
    }

    &__list {
        padding: 0 0 $cs-spaces.double;

        &:last-child {
            padding-bottom: 0;
        }

        @media $display-breakpoints.sm-and-up {
            padding: 0 $cs-spaces.onehalf;
        }

        .h5 {
            margin: 0 0 $cs-spaces.half;
        }
    }


    &__itemNumber {
        width: $cs-spaces.double;
        height: $cs-spaces.double;
        background-color: $cs-slate-400;
        color: $cs-white;
        font-size: 20px;
        font-weight: 700;
        border-radius: $cs-border-radius-round;
        margin: auto;
        margin-bottom: $cs-spaces.half;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -1px;
    }

    &__image {
        width: 80%;
        display: block;
        margin: 0 auto $cs-spaces.onehalf;
        max-width: 320px;

        @media $display-breakpoints.sm-and-up {
            margin: 0 auto $cs-spaces.double;
        }
    }

    .icon__listThreeColumns {
        width: 36px;
        height: 36px;
        margin: 0 0 $cs-spaces.half;
        color: $cs-primary;
    }

    .prices-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 30px;

        @media $display-breakpoints.sm-and-down {
            flex-direction: column
        }
    }
}


.with_disk {
    list-style-type: disc;
    padding-left: 20px;
}

.tableContainer {
    display: block;
    width: 50%;
    margin: 0 10px 20px 0;

    @media $display-breakpoints.sm-and-down {
        width: 100%;
    }
}

.table {
    border: 1px solid black;
    border-spacing: 0;
    border-radius: 6px;

    &__header {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    &__mainTitle {
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
        text-align: center;
        padding: 6px;
    }

    &__borderBottom {
        border-bottom: 1px solid black;
    }

    &__borderLeft {
        border-left: 1px solid black;
    }

    &__font_heavy {
        font-weight: 600;
    }

    &__centre {
        text-align: center;
    }

    &__row {
        display: flex;
        width: 100%;
        flex-direction: row;

        > div {
            padding: 6px;
        }
    }

    &__col1 {
        width: 33%;
    }

    &__col2 {
        width: 66%
    }
}
</style>
