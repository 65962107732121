<template>
    <div class="uspSection">
        <div
            v-if="title || subline"
            class="header__title header__title--center"
        >
            <h2 v-if="title" v-text="title" />
            <p v-if="subline" class="header__subline" v-text="subline" />
            <BestMatchDialog
                v-if="showBestMatch"
                v-bind="bestMatch"
                class="uspSection__bestMatch header__subline"
            />
        </div>

        <UspList :usp-list="uspList" :bar="bar" class="uspSection__list" />
    </div>
</template>

<script>
import UspList from '@/components/molecules/usps/uspList.vue'
import BestMatchDialog from '@/components/organisms/chemistryGuarantee/bestMatchDialog.vue'

export default {
    name: 'UspSection',

    components: {
        UspList,
        BestMatchDialog,
    },

    props: {
        title: {
            type: String,
            default: 'Ihr Alltagshelfer aus der Nachbarschaft',
        },

        subline: {
            type: String,
            default: 'Zuverlässige Unterstützung für Sie und Ihre Liebsten.',
        },

        uspList: {
            type: Array,
            validator: value => typeof value === 'object' || !value,
            default: () => [
                {
                    icon: 'touch-id-user',
                    text: 'Immer dieselbe Person',
                },
                {
                    icon: 'family',
                    text: 'Keine Mindestvertragslaufzeit',
                },
                {
                    icon: 'business-contract-approve',
                    text: 'Geprüft und haftplichtversichert',
                },
                {
                    icon: 'business-deal-cash-exchange',
                    text: 'Flexibel buchen nach Bedarf',
                },
            ],
        },

        bar: {
            type: Boolean,
            default: false,
        },

        showBestMatch: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        displayDialog: false,
        bestMatch: {
            icon: null,
            ctaText: '„Chemie-stimmt-Garantie“',
            textStart: 'Mit',
            textEnd: '',
        },
    }),
}
</script>
<style lang="stylus">
.uspSection {
    &__bestMatch.header__subline {
        display: block;
        margin-top: $cs-spaces.half;
    }
}
</style>
